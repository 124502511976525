.container {
    width: 100%;
    background-color: #f5f2e9;
  }
  
  .top_cover {
    border-radius: 0 0 40px 40px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    max-height: 670px;
  }
  
  .intro {
    background-color: #f5f2e9;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  .introframe {
    border-radius: 50px 5px;
    background: #ea2f6f;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.70);
    width: 90%;
    height: 438px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -180px;
    padding: 26px 0;
  }
  
  .intro_upper {
    text-align: center;
  }
  
  .intro_heading {
    color: var(--Neutrals-Colors-100, #FFF);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Playfair Display";
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
  }
  
  .subheading_intro {
    color: var(--Neutrals-Colors-100, #FFF);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
  }
  
  .matrimony_desc {
    color: var(--Neutrals-Colors-100, #FFF);
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    width: 826px;
    margin: 30px 0;
    word-break: break-word;
  }
  
  .intro_footer {
    color: var(--Neutrals-Colors-100, #FFF);
    font-family: "Playfair Display";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 150% */
    text-transform: uppercase;
    text-align: right;
    width: 100%;
    margin-right: 128px;
  }
  .footer_spn{
    font-size: 42px;
    font-weight: 900;
  }


  /* --------------------- bio data --------------------------------- */

  .biodata {
    align-self: end;
    z-index: 10;
    margin-top: -40px;
    width: 100%;
    background-color: white;
  }

  .contentWrapper {
    display: flex;
    justify-content: center;
    padding: 0 20px;
  }
  .textColumn{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
  

  .textContent {
    text-align: left;
  }
  

  
  .title {
    color: #000;
    font-family: "Playfair Display";
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: 55.2px; 
    text-align: left;

  }
  
  
  .ctaButton {
    border-radius: 15px;
    background-color: #EA2F6F;
    align-self: start;
    margin-top: 15px;
    color: #FFF;
    text-transform: uppercase;
    padding: 0px 26px 0px 25px;
    font: 500 15px/40px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    outline: none;
    border: none;
    text-decoration: none;
  }
  
  
  .imageColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 55%;

  }
  
  
  .bioImage {
    aspect-ratio: 1.98;
    object-fit: contain;
    object-position: center;
    width: 100%;
    flex-grow: 1;
    position: relative;
    top: -80px;
  }
  
  @media (max-width: 991px) {
    .introframe{
      zoom: 40%;
    }
    .matrimony_desc{
      font-size: 23px;
    }
    .contentWrapper{
      flex-direction: column-reverse;
      margin-bottom: 30px;
    }
    .imageColumn{
      width: auto;
    }
    .bioImage{
      top: 0px;
    }
    .title{
      font-size: 21px;
      line-height: 34.2px;
    }
    .ctaButton{
      font-size: 12px;
      padding: 0px 18px 0px 20px;
    }
}

