.container {
  display: flex;
  width: 100%;
  height: 285px;
  flex-direction: column;
  align-items: center;
  background: #A8A6CE;

  }
  

  .toptitle{
    color: var(--Neutrals-Colors-100, #FFF);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Playfair Display";
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    margin: 30px 0 0 0;
    padding: 0;
  }
  .cardList {
    display: flex;
    justify-content: center;
    gap: 5%;
  }
  

  .cardWrapper {
    margin-bottom: 20px;
    position: relative;
    bottom: -32px;
  }

  
  .card {
    border-radius: 5px;
    background: var(--rn-53-themes-net-nero, #FFF);
    box-shadow: 0px 1px 14px -3px rgba(51, 51, 51, 0.13);
    display: flex;
    width: 350px;
    min-height: 205px;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    padding: 29px 30px;
  }
  

  .icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 50px;
    align-self: center;
  }
  
  .title {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    padding: 20px 0 5px;
    text-align: center;
  }
  
  .description {
    width: 100%;
    padding-top: 8px;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
  }

  @media (max-width: 991px) {
    .container{
      height: auto;
    }
    .toptitle{
      font-size: 30px
    }
    .card{
      max-width: 17rem;
      min-height: 149px;
    }
    .cardList{
      flex-direction: column;
      padding-bottom: 50px;
    }
  }
  