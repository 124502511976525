
  .container{
    background: var(--preview-themeforest-net-black, #000);
    display: flex;
    flex-direction: column;
  }
  .upperfooter {
    color: #fff;
    font-family: Poppins;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    text-align: left;
  }
  .footersection {
    text-align: left;
    width: 30%;
    margin-right: 5px
  }
  .footerTitle {
    color: var(--preview-themeforest-net-white, var(--Neutrals-Colors-100, #FFF));
    font-family: 'Prociono';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.4px; /* 120% */
    margin-bottom: 30px;
  }
  .socialLinks{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
  .iconLink{
    width: 21px;
    height: 21px;
  }
  .arrow {
    transition: transform 0.3s ease;
    margin-right: 10px;
    display: inline-block; /* Ensure the arrow stays inline with the text */
    vertical-align: middle; /* Align the arrow vertically with the text */
  }
  
  .arrowDown {
    transform: rotate(90deg); /* Rotate the arrow */
  }
  
  .expandedText {
    padding-left: 25px;
    padding-right: 10px;
  }
  .answer{
    cursor: pointer;
  }
  

  .footersection p {
    color: var(--preview-themeforest-net-white, var(--Neutrals-Colors-100, #FFF));
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 27.2px; /* 170% */
    text-decoration-line: none;
    padding-bottom: 10px;
  }
  .footersection a {
    color: #fff;
    text-decoration: none;
  }
  .footersection a:hover {
    text-decoration: underline;
  }

  .form-section {
    width: 20%;
    position: relative;
    left: -50px;

  }
  .container input[type="text"], .footersection
  input[type="email"] {
    color: #fff;
    background: var(--preview-themeforest-net-black, #000);
    border: none; 
    border-bottom: 2px solid var(--preview-themeforest-net-white, #FFF);
    outline: none; 
    padding: 8px; 
    width: 279.13px;
    display: flex;
    font-family: Poppins;
  }
  .messageInput {
    width: 93%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    background: var(--preview-themeforest-net-black, #000);
    border-radius: 4px;
    resize: none;
    color: #fff;
    font-family: Poppins;
  }
  
  .sendmsgbutton {
    background: var(--preview-themeforest-net-black, #000);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: 2px solid var(--preview-themeforest-net-white, #FFF);
    cursor: pointer;
    font-family: Poppins;
  }

  .sendmsgbutton:hover {
    background-color:#f5a623 ; /* Darker green on hover */
  }
 .divider {
    border-top: 0.667px solid var(--preview-themeforest-net-dove-gray, #726E6E); 
    margin: 0 auto;
    width: 94%;
  }
  .footerbottom {
    display: flex;
    padding: 30.07px 0px 14.99px 0px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    color: var(--preview-themeforest-net-white, var(--Neutrals-Colors-100, #FFF));
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px;
  }


@media (max-width: 768px) {
  .container {
    zoom: 80%;
  }
  .upperfooter{
    flex-direction: column;
  }
  .footersection{
    width: 100%;
    margin-bottom: 20px;
  }
  .footerTitle{
    margin-bottom: 15px
  }
}
