.container {
    width: 80%;
    margin: 5rem auto;
    font-family: Nunito;
  }
  
  .inputGroup, .checkboxGroup {
    margin-bottom: 1.5rem;
  }
  
  .row {
    display: flex;
    gap: 1.5rem;
  }
  
  .column {
    flex: 1;
    margin-bottom: 1.5rem;
  }
  .title{
    padding: 10px 0;
    color: #484848;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .label {
    display: block;
    margin-bottom: 0.5rem;
    color: #484848;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .inputField{
    width: 100%;
    padding: 0.638rem;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box; 
  }
  
  .inputField:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .checkboxGroup {
    display: flex;
    align-items: center;
  }
  
  .checkbox {
    margin-right: 0.5rem;
  }
  
  .checkboxLabel {
    font-size: 1rem;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: space-between;
  }
  
  .button {
    background-color: #070e16;
    color: white;
    border: none;
    padding: 0.75rem 4rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #1b1b28;
  }
  