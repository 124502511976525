:root {
  --preview-themeforest-net-font-awesome-5-free-solid-14-font-family: "Font Awesome 5 Free", Helvetica;
  --preview-themeforest-net-font-awesome-5-free-solid-14-font-size: 14px;
  --preview-themeforest-net-font-awesome-5-free-solid-14-font-style: normal;
  --preview-themeforest-net-font-awesome-5-free-solid-14-font-weight: 400;
  --preview-themeforest-net-font-awesome-5-free-solid-14-letter-spacing: 0px;
  --preview-themeforest-net-font-awesome-5-free-solid-14-line-height: 14px;
  --preview-themeforest-net-IBM-plex-sans-regular-14-font-family: "IBM Plex Sans", Helvetica;
  --preview-themeforest-net-IBM-plex-sans-regular-14-font-size: 14px;
  --preview-themeforest-net-IBM-plex-sans-regular-14-font-style: normal;
  --preview-themeforest-net-IBM-plex-sans-regular-14-font-weight: 400;
  --preview-themeforest-net-IBM-plex-sans-regular-14-letter-spacing: 0px;
  --preview-themeforest-net-IBM-plex-sans-regular-14-line-height: normal;
  --preview-themeforest-net-inter-regular-11-25-underline-font-family: "Inter", Helvetica;
  --preview-themeforest-net-inter-regular-11-25-underline-font-size: 11.25px;
  --preview-themeforest-net-inter-regular-11-25-underline-font-style: normal;
  --preview-themeforest-net-inter-regular-11-25-underline-font-weight: 400;
  --preview-themeforest-net-inter-regular-11-25-underline-letter-spacing: 0px;
  --preview-themeforest-net-inter-regular-11-25-underline-line-height: 54px;
  --preview-themeforest-net-inter-regular-13-23-font-family: "Inter", Helvetica;
  --preview-themeforest-net-inter-regular-13-23-font-size: 13.234375px;
  --preview-themeforest-net-inter-regular-13-23-font-style: normal;
  --preview-themeforest-net-inter-regular-13-23-font-weight: 400;
  --preview-themeforest-net-inter-regular-13-23-letter-spacing: 0px;
  --preview-themeforest-net-inter-regular-13-23-line-height: 21px;
  --preview-themeforest-net-poppins-extrabold-26-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-extrabold-26-font-size: 26px;
  --preview-themeforest-net-poppins-extrabold-26-font-style: normal;
  --preview-themeforest-net-poppins-extrabold-26-font-weight: 800;
  --preview-themeforest-net-poppins-extrabold-26-letter-spacing: 0px;
  --preview-themeforest-net-poppins-extrabold-26-line-height: 31.200000762939453px;
  --preview-themeforest-net-poppins-light-16-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-light-16-font-size: 16px;
  --preview-themeforest-net-poppins-light-16-font-style: normal;
  --preview-themeforest-net-poppins-light-16-font-weight: 300;
  --preview-themeforest-net-poppins-light-16-letter-spacing: 0px;
  --preview-themeforest-net-poppins-light-16-line-height: normal;
  --preview-themeforest-net-poppins-light-italic-16-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-light-italic-16-font-size: 16px;
  --preview-themeforest-net-poppins-light-italic-16-font-style: italic;
  --preview-themeforest-net-poppins-light-italic-16-font-weight: 300;
  --preview-themeforest-net-poppins-light-italic-16-letter-spacing: 0px;
  --preview-themeforest-net-poppins-light-italic-16-line-height: 19.200000762939453px;
  --preview-themeforest-net-poppins-medium-13-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-medium-13-font-size: 13px;
  --preview-themeforest-net-poppins-medium-13-font-style: normal;
  --preview-themeforest-net-poppins-medium-13-font-weight: 500;
  --preview-themeforest-net-poppins-medium-13-letter-spacing: 0px;
  --preview-themeforest-net-poppins-medium-13-line-height: 15.600000381469727px;
  --preview-themeforest-net-poppins-medium-15-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-medium-15-font-size: 15px;
  --preview-themeforest-net-poppins-medium-15-font-style: normal;
  --preview-themeforest-net-poppins-medium-15-font-weight: 500;
  --preview-themeforest-net-poppins-medium-15-letter-spacing: 0px;
  --preview-themeforest-net-poppins-medium-15-line-height: 18px;
  --preview-themeforest-net-poppins-medium-16-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-medium-16-font-size: 16px;
  --preview-themeforest-net-poppins-medium-16-font-style: normal;
  --preview-themeforest-net-poppins-medium-16-font-weight: 500;
  --preview-themeforest-net-poppins-medium-16-letter-spacing: 0px;
  --preview-themeforest-net-poppins-medium-16-line-height: 24px;
  --preview-themeforest-net-poppins-regular-15-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-regular-15-font-size: 15px;
  --preview-themeforest-net-poppins-regular-15-font-style: normal;
  --preview-themeforest-net-poppins-regular-15-font-weight: 400;
  --preview-themeforest-net-poppins-regular-15-letter-spacing: 0px;
  --preview-themeforest-net-poppins-regular-15-line-height: 18px;
  --preview-themeforest-net-poppins-regular-16-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-regular-16-font-size: 16px;
  --preview-themeforest-net-poppins-regular-16-font-style: normal;
  --preview-themeforest-net-poppins-regular-16-font-weight: 400;
  --preview-themeforest-net-poppins-regular-16-letter-spacing: 0px;
  --preview-themeforest-net-poppins-regular-16-line-height: 19.200000762939453px;
  --preview-themeforest-net-poppins-semibold-70-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-semibold-70-font-size: 70px;
  --preview-themeforest-net-poppins-semibold-70-font-style: normal;
  --preview-themeforest-net-poppins-semibold-70-font-weight: 600;
  --preview-themeforest-net-poppins-semibold-70-letter-spacing: 0px;
  --preview-themeforest-net-poppins-semibold-70-line-height: 84px;
  --preview-themeforest-net-prociono-regular-18-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-18-font-size: 18px;
  --preview-themeforest-net-prociono-regular-18-font-style: normal;
  --preview-themeforest-net-prociono-regular-18-font-weight: 400;
  --preview-themeforest-net-prociono-regular-18-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-18-line-height: 21.600000381469727px;
  --preview-themeforest-net-prociono-regular-19-84-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-19-84-font-size: 19.84375px;
  --preview-themeforest-net-prociono-regular-19-84-font-style: normal;
  --preview-themeforest-net-prociono-regular-19-84-font-weight: 400;
  --preview-themeforest-net-prociono-regular-19-84-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-19-84-line-height: 24px;
  --preview-themeforest-net-prociono-regular-20-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-20-font-size: 20px;
  --preview-themeforest-net-prociono-regular-20-font-style: normal;
  --preview-themeforest-net-prociono-regular-20-font-weight: 400;
  --preview-themeforest-net-prociono-regular-20-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-20-line-height: 24px;
  --preview-themeforest-net-prociono-regular-22-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-22-font-size: 22px;
  --preview-themeforest-net-prociono-regular-22-font-style: normal;
  --preview-themeforest-net-prociono-regular-22-font-weight: 400;
  --preview-themeforest-net-prociono-regular-22-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-22-line-height: 26.399999618530273px;
  --preview-themeforest-net-prociono-regular-48-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-48-font-size: 48px;
  --preview-themeforest-net-prociono-regular-48-font-style: normal;
  --preview-themeforest-net-prociono-regular-48-font-weight: 400;
  --preview-themeforest-net-prociono-regular-48-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-48-line-height: 57.599998474121094px;
  --preview-themeforest-net-prociono-regular-65-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-65-font-size: 65px;
  --preview-themeforest-net-prociono-regular-65-font-style: normal;
  --preview-themeforest-net-prociono-regular-65-font-weight: 400;
  --preview-themeforest-net-prociono-regular-65-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-65-line-height: 78px;
  --preview-themeforest-net-prociono-regular-70-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-70-font-size: 70px;
  --preview-themeforest-net-prociono-regular-70-font-style: normal;
  --preview-themeforest-net-prociono-regular-70-font-weight: 400;
  --preview-themeforest-net-prociono-regular-70-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-70-line-height: 84px;
  --previewthemeforestnetblack: rgba(0, 0, 0, 1);
  --previewthemeforestnetblack-50: rgba(0, 0, 0, 0.5);
  --previewthemeforestnetblack-52: rgba(0, 0, 0, 0.52);
  --previewthemeforestnetblack-66: rgba(0, 0, 0, 0.66);
  --previewthemeforestnetblue: rgba(0, 0, 238, 1);
  --previewthemeforestnetcod-gray: rgba(17, 17, 17, 1);
  --previewthemeforestnetdeep-bronze: rgba(81, 54, 6, 1);
  --previewthemeforestnetdove-gray: rgba(114, 110, 110, 1);
  --previewthemeforestnetivory: rgba(248, 243, 243, 1);
  --previewthemeforestnetmine-shaft: rgba(38, 38, 38, 1);
  --previewthemeforestnetold-lace: rgba(253, 243, 226, 1);
  --previewthemeforestnetoslo-gray: rgba(135, 137, 145, 1);
  --previewthemeforestnetsanta-fe: rgba(169, 116, 83, 1);
  --previewthemeforestnetsushi: rgba(130, 180, 64, 1);
  --previewthemeforestnetwhite: rgba(255, 255, 255, 1);
  --previewthemeforestnetwhite-02: rgba(255, 255, 255, 0);
  --previewthemeforestnetwhite-32: rgba(255, 255, 255, 0.32);
  --previewthemeforestnetwhite-black: rgba(255, 255, 255, 1);
  --rn-5-3themesnetalto: rgba(217, 217, 217, 1);
  --rn-5-3themesnetblack: rgba(0, 0, 0, 1);
  --rn-5-3themesnetbone: rgba(221, 206, 188, 1);
  --rn-5-3themesnetboulder: rgba(122, 122, 122, 1);
  --rn-5-3themesnetemperor: rgba(85, 85, 85, 1);
  --rn-5-3themesnetfiord: rgba(65, 87, 100, 1);
  --rn-5-3themesnetgeraldine: rgba(253, 134, 134, 1);
  --rn-5-3themesnetgreen: rgba(255, 180, 0, 1);
  --rn-5-3themesnetheather: rgba(185, 198, 207, 1);
  --rn-5-3themesnetlynch: rgba(96, 125, 139, 1);
  --rn-5-3themesnetmercury: rgba(231, 231, 231, 1);
  --rn-5-3themesnetmerino: rgba(243, 238, 220, 1);
  --rn-5-3themesnetmongoose: rgba(183, 155, 121, 1);
  --rn-5-3themesnetmystic: rgba(219, 229, 234, 1);
  --rn-5-3themesnetnero: rgba(255, 255, 255, 1);
  --rn-5-3themesnetrazzmatazz: rgba(235, 3, 89, 1);
  --rn-5-3themesnetred: rgba(236, 255, 0, 1);
  --rn-5-3themesnetsandstone: rgba(116, 106, 99, 1);
  --rn-5-3themesnetsisal: rgba(215, 209, 190, 1);
  --rn-5-3themesnettussock: rgba(196, 140, 70, 1);
  --rn-5-3themesnetvictoria: rgba(75, 62, 126, 1);
  --rn-5-3themesnetwafer: rgba(219, 204, 187, 1);
  --rn53themes-net-cinzel-decorative-bold-17-72-font-family: "Cinzel Decorative", Helvetica;
  --rn53themes-net-cinzel-decorative-bold-17-72-font-size: 17.71875px;
  --rn53themes-net-cinzel-decorative-bold-17-72-font-style: normal;
  --rn53themes-net-cinzel-decorative-bold-17-72-font-weight: 700;
  --rn53themes-net-cinzel-decorative-bold-17-72-letter-spacing: 0px;
  --rn53themes-net-cinzel-decorative-bold-17-72-line-height: 21.600000381469727px;
  --rn53themes-net-cinzel-decorative-bold-20-upper-font-family: "Cinzel Decorative", Helvetica;
  --rn53themes-net-cinzel-decorative-bold-20-upper-font-size: 20px;
  --rn53themes-net-cinzel-decorative-bold-20-upper-font-style: normal;
  --rn53themes-net-cinzel-decorative-bold-20-upper-font-weight: 700;
  --rn53themes-net-cinzel-decorative-bold-20-upper-letter-spacing: 5px;
  --rn53themes-net-cinzel-decorative-bold-20-upper-line-height: 30px;
  --rn53themes-net-cinzel-decorative-bold-35-font-family: "Cinzel Decorative", Helvetica;
  --rn53themes-net-cinzel-decorative-bold-35-font-size: 35px;
  --rn53themes-net-cinzel-decorative-bold-35-font-style: normal;
  --rn53themes-net-cinzel-decorative-bold-35-font-weight: 700;
  --rn53themes-net-cinzel-decorative-bold-35-letter-spacing: 0px;
  --rn53themes-net-cinzel-decorative-bold-35-line-height: 78px;
  --rn53themes-net-cinzel-decorative-bold-40-font-family: "Cinzel Decorative", Helvetica;
  --rn53themes-net-cinzel-decorative-bold-40-font-size: 40px;
  --rn53themes-net-cinzel-decorative-bold-40-font-style: normal;
  --rn53themes-net-cinzel-decorative-bold-40-font-weight: 700;
  --rn53themes-net-cinzel-decorative-bold-40-letter-spacing: 0px;
  --rn53themes-net-cinzel-decorative-bold-40-line-height: 48px;
  --rn53themes-net-cinzel-decorative-bold-58-font-family: "Cinzel Decorative", Helvetica;
  --rn53themes-net-cinzel-decorative-bold-58-font-size: 58px;
  --rn53themes-net-cinzel-decorative-bold-58-font-style: normal;
  --rn53themes-net-cinzel-decorative-bold-58-font-weight: 700;
  --rn53themes-net-cinzel-decorative-bold-58-letter-spacing: 0px;
  --rn53themes-net-cinzel-decorative-bold-58-line-height: 44px;
  --rn53themes-net-cinzel-decorative-regular-19-font-family: "Cinzel Decorative", Helvetica;
  --rn53themes-net-cinzel-decorative-regular-19-font-size: 19px;
  --rn53themes-net-cinzel-decorative-regular-19-font-style: normal;
  --rn53themes-net-cinzel-decorative-regular-19-font-weight: 400;
  --rn53themes-net-cinzel-decorative-regular-19-letter-spacing: 0px;
  --rn53themes-net-cinzel-decorative-regular-19-line-height: 28px;
  --rn53themes-net-josefin-sans-regular-16-font-family: "Josefin Sans", Helvetica;
  --rn53themes-net-josefin-sans-regular-16-font-size: 16px;
  --rn53themes-net-josefin-sans-regular-16-font-style: normal;
  --rn53themes-net-josefin-sans-regular-16-font-weight: 400;
  --rn53themes-net-josefin-sans-regular-16-letter-spacing: 0px;
  --rn53themes-net-josefin-sans-regular-16-line-height: 24px;
  --rn53themes-net-josefin-sans-semibold-16-upper-font-family: "Josefin Sans", Helvetica;
  --rn53themes-net-josefin-sans-semibold-16-upper-font-size: 16px;
  --rn53themes-net-josefin-sans-semibold-16-upper-font-style: normal;
  --rn53themes-net-josefin-sans-semibold-16-upper-font-weight: 600;
  --rn53themes-net-josefin-sans-semibold-16-upper-letter-spacing: 0px;
  --rn53themes-net-josefin-sans-semibold-16-upper-line-height: 28px;
  --rn53themes-net-josefin-sans-semibold-18-font-family: "Josefin Sans", Helvetica;
  --rn53themes-net-josefin-sans-semibold-18-font-size: 18px;
  --rn53themes-net-josefin-sans-semibold-18-font-style: normal;
  --rn53themes-net-josefin-sans-semibold-18-font-weight: 600;
  --rn53themes-net-josefin-sans-semibold-18-letter-spacing: 1px;
  --rn53themes-net-josefin-sans-semibold-18-line-height: 35px;
  --rn53themes-net-playfair-display-bold-32-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-bold-32-font-size: 32px;
  --rn53themes-net-playfair-display-bold-32-font-style: normal;
  --rn53themes-net-playfair-display-bold-32-font-weight: 700;
  --rn53themes-net-playfair-display-bold-32-letter-spacing: 0px;
  --rn53themes-net-playfair-display-bold-32-line-height: 36px;
  --rn53themes-net-playfair-display-bold-65-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-bold-65-font-size: 65px;
  --rn53themes-net-playfair-display-bold-65-font-style: normal;
  --rn53themes-net-playfair-display-bold-65-font-weight: 700;
  --rn53themes-net-playfair-display-bold-65-letter-spacing: 0px;
  --rn53themes-net-playfair-display-bold-65-line-height: 70px;
  --rn53themes-net-playfair-display-bold-72-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-bold-72-font-size: 72px;
  --rn53themes-net-playfair-display-bold-72-font-style: normal;
  --rn53themes-net-playfair-display-bold-72-font-weight: 700;
  --rn53themes-net-playfair-display-bold-72-letter-spacing: 0px;
  --rn53themes-net-playfair-display-bold-72-line-height: 70px;
  --rn53themes-net-playfair-display-extrabold-80-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-extrabold-80-font-size: 80px;
  --rn53themes-net-playfair-display-extrabold-80-font-style: normal;
  --rn53themes-net-playfair-display-extrabold-80-font-weight: 800;
  --rn53themes-net-playfair-display-extrabold-80-letter-spacing: 0px;
  --rn53themes-net-playfair-display-extrabold-80-line-height: 100px;
  --rn53themes-net-playfair-display-medium-24-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-medium-24-font-size: 24px;
  --rn53themes-net-playfair-display-medium-24-font-style: normal;
  --rn53themes-net-playfair-display-medium-24-font-weight: 500;
  --rn53themes-net-playfair-display-medium-24-letter-spacing: 0px;
  --rn53themes-net-playfair-display-medium-24-line-height: 28.799999237060547px;
  --rn53themes-net-playfair-display-medium-26-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-medium-26-font-size: 26px;
  --rn53themes-net-playfair-display-medium-26-font-style: normal;
  --rn53themes-net-playfair-display-medium-26-font-weight: 500;
  --rn53themes-net-playfair-display-medium-26-letter-spacing: 0px;
  --rn53themes-net-playfair-display-medium-26-line-height: 31.200000762939453px;
  --rn53themes-net-playfair-display-medium-30-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-medium-30-font-size: 30px;
  --rn53themes-net-playfair-display-medium-30-font-style: normal;
  --rn53themes-net-playfair-display-medium-30-font-weight: 500;
  --rn53themes-net-playfair-display-medium-30-letter-spacing: 0px;
  --rn53themes-net-playfair-display-medium-30-line-height: 36px;
  --rn53themes-net-playfair-display-regular-22-upper-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-regular-22-upper-font-size: 22px;
  --rn53themes-net-playfair-display-regular-22-upper-font-style: normal;
  --rn53themes-net-playfair-display-regular-22-upper-font-weight: 400;
  --rn53themes-net-playfair-display-regular-22-upper-letter-spacing: 0px;
  --rn53themes-net-playfair-display-regular-22-upper-line-height: 33px;
  --rn53themes-net-playfair-display-semibold-42-upper-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-semibold-42-upper-font-size: 42px;
  --rn53themes-net-playfair-display-semibold-42-upper-font-style: normal;
  --rn53themes-net-playfair-display-semibold-42-upper-font-weight: 600;
  --rn53themes-net-playfair-display-semibold-42-upper-letter-spacing: 0px;
  --rn53themes-net-playfair-display-semibold-42-upper-line-height: 63px;
  --rn53themes-net-playfair-display-semibold-46-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-semibold-46-font-size: 46px;
  --rn53themes-net-playfair-display-semibold-46-font-style: normal;
  --rn53themes-net-playfair-display-semibold-46-font-weight: 600;
  --rn53themes-net-playfair-display-semibold-46-letter-spacing: 0px;
  --rn53themes-net-playfair-display-semibold-46-line-height: 55.20000076293945px;
  --rn53themes-net-playfair-display-semibold-90-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-semibold-90-font-size: 90px;
  --rn53themes-net-playfair-display-semibold-90-font-style: normal;
  --rn53themes-net-playfair-display-semibold-90-font-weight: 600;
  --rn53themes-net-playfair-display-semibold-90-letter-spacing: 0px;
  --rn53themes-net-playfair-display-semibold-90-line-height: 70px;
  --rn53themes-net-poppins-bold-14-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-bold-14-upper-font-size: 14px;
  --rn53themes-net-poppins-bold-14-upper-font-style: normal;
  --rn53themes-net-poppins-bold-14-upper-font-weight: 700;
  --rn53themes-net-poppins-bold-14-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-bold-14-upper-line-height: 16.799999237060547px;
  --rn53themes-net-poppins-bold-15-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-bold-15-font-size: 15px;
  --rn53themes-net-poppins-bold-15-font-style: normal;
  --rn53themes-net-poppins-bold-15-font-weight: 700;
  --rn53themes-net-poppins-bold-15-letter-spacing: 0px;
  --rn53themes-net-poppins-bold-15-line-height: 22.5px;
  --rn53themes-net-poppins-bold-16-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-bold-16-font-size: 16px;
  --rn53themes-net-poppins-bold-16-font-style: normal;
  --rn53themes-net-poppins-bold-16-font-weight: 700;
  --rn53themes-net-poppins-bold-16-letter-spacing: 0px;
  --rn53themes-net-poppins-bold-16-line-height: 15px;
  --rn53themes-net-poppins-bold-20-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-bold-20-font-size: 20px;
  --rn53themes-net-poppins-bold-20-font-style: normal;
  --rn53themes-net-poppins-bold-20-font-weight: 700;
  --rn53themes-net-poppins-bold-20-letter-spacing: 0px;
  --rn53themes-net-poppins-bold-20-line-height: 24px;
  --rn53themes-net-poppins-light-20-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-light-20-font-size: 20px;
  --rn53themes-net-poppins-light-20-font-style: normal;
  --rn53themes-net-poppins-light-20-font-weight: 300;
  --rn53themes-net-poppins-light-20-letter-spacing: 0px;
  --rn53themes-net-poppins-light-20-line-height: 28px;
  --rn53themes-net-poppins-medium-10-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-10-upper-font-size: 10px;
  --rn53themes-net-poppins-medium-10-upper-font-style: normal;
  --rn53themes-net-poppins-medium-10-upper-font-weight: 500;
  --rn53themes-net-poppins-medium-10-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-10-upper-line-height: 15px;
  --rn53themes-net-poppins-medium-11-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-11-font-size: 11px;
  --rn53themes-net-poppins-medium-11-font-style: normal;
  --rn53themes-net-poppins-medium-11-font-weight: 500;
  --rn53themes-net-poppins-medium-11-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-11-line-height: 16.5px;
  --rn53themes-net-poppins-medium-11-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-11-upper-font-size: 11px;
  --rn53themes-net-poppins-medium-11-upper-font-style: normal;
  --rn53themes-net-poppins-medium-11-upper-font-weight: 500;
  --rn53themes-net-poppins-medium-11-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-11-upper-line-height: 16.5px;
  --rn53themes-net-poppins-medium-13-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-13-font-size: 13px;
  --rn53themes-net-poppins-medium-13-font-style: normal;
  --rn53themes-net-poppins-medium-13-font-weight: 500;
  --rn53themes-net-poppins-medium-13-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-13-line-height: 22px;
  --rn53themes-net-poppins-medium-13-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-13-upper-font-size: 13px;
  --rn53themes-net-poppins-medium-13-upper-font-style: normal;
  --rn53themes-net-poppins-medium-13-upper-font-weight: 500;
  --rn53themes-net-poppins-medium-13-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-13-upper-line-height: 19.5px;
  --rn53themes-net-poppins-medium-14-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-14-font-size: 14px;
  --rn53themes-net-poppins-medium-14-font-style: normal;
  --rn53themes-net-poppins-medium-14-font-weight: 500;
  --rn53themes-net-poppins-medium-14-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-14-line-height: 21px;
  --rn53themes-net-poppins-medium-14-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-14-upper-font-size: 14px;
  --rn53themes-net-poppins-medium-14-upper-font-style: normal;
  --rn53themes-net-poppins-medium-14-upper-font-weight: 500;
  --rn53themes-net-poppins-medium-14-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-14-upper-line-height: 40px;
  --rn53themes-net-poppins-medium-15-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-15-font-size: 15px;
  --rn53themes-net-poppins-medium-15-font-style: normal;
  --rn53themes-net-poppins-medium-15-font-weight: 500;
  --rn53themes-net-poppins-medium-15-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-15-line-height: 22px;
  --rn53themes-net-poppins-medium-23-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-23-font-size: 23px;
  --rn53themes-net-poppins-medium-23-font-style: normal;
  --rn53themes-net-poppins-medium-23-font-weight: 500;
  --rn53themes-net-poppins-medium-23-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-23-line-height: 27.600000381469727px;
  --rn53themes-net-poppins-regular-13-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-13-font-size: 13px;
  --rn53themes-net-poppins-regular-13-font-style: normal;
  --rn53themes-net-poppins-regular-13-font-weight: 400;
  --rn53themes-net-poppins-regular-13-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-13-line-height: 19.5px;
  --rn53themes-net-poppins-regular-14-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-14-font-size: 14px;
  --rn53themes-net-poppins-regular-14-font-style: normal;
  --rn53themes-net-poppins-regular-14-font-weight: 400;
  --rn53themes-net-poppins-regular-14-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-14-line-height: 21px;
  --rn53themes-net-poppins-regular-14-underline-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-14-underline-font-size: 14px;
  --rn53themes-net-poppins-regular-14-underline-font-style: normal;
  --rn53themes-net-poppins-regular-14-underline-font-weight: 400;
  --rn53themes-net-poppins-regular-14-underline-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-14-underline-line-height: 21px;
  --rn53themes-net-poppins-regular-14-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-14-upper-font-size: 14px;
  --rn53themes-net-poppins-regular-14-upper-font-style: normal;
  --rn53themes-net-poppins-regular-14-upper-font-weight: 400;
  --rn53themes-net-poppins-regular-14-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-14-upper-line-height: 21px;
  --rn53themes-net-poppins-regular-15-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-15-font-size: 15px;
  --rn53themes-net-poppins-regular-15-font-style: normal;
  --rn53themes-net-poppins-regular-15-font-weight: 400;
  --rn53themes-net-poppins-regular-15-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-15-line-height: 26px;
  --rn53themes-net-poppins-regular-16-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-16-font-size: 16px;
  --rn53themes-net-poppins-regular-16-font-style: normal;
  --rn53themes-net-poppins-regular-16-font-weight: 400;
  --rn53themes-net-poppins-regular-16-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-16-line-height: 27px;
  --rn53themes-net-poppins-regular-20-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-20-font-size: 20px;
  --rn53themes-net-poppins-regular-20-font-style: normal;
  --rn53themes-net-poppins-regular-20-font-weight: 400;
  --rn53themes-net-poppins-regular-20-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-20-line-height: 30px;
  --rn53themes-net-poppins-semibold-10-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-semibold-10-upper-font-size: 10px;
  --rn53themes-net-poppins-semibold-10-upper-font-style: normal;
  --rn53themes-net-poppins-semibold-10-upper-font-weight: 600;
  --rn53themes-net-poppins-semibold-10-upper-letter-spacing: 0.5px;
  --rn53themes-net-poppins-semibold-10-upper-line-height: 20px;
  --rn53themes-net-poppins-semibold-13-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-semibold-13-upper-font-size: 13px;
  --rn53themes-net-poppins-semibold-13-upper-font-style: normal;
  --rn53themes-net-poppins-semibold-13-upper-font-weight: 600;
  --rn53themes-net-poppins-semibold-13-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-semibold-13-upper-line-height: 30px;
  --rn53themes-net-poppins-semibold-16-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-semibold-16-font-size: 16px;
  --rn53themes-net-poppins-semibold-16-font-style: normal;
  --rn53themes-net-poppins-semibold-16-font-weight: 600;
  --rn53themes-net-poppins-semibold-16-letter-spacing: 0px;
  --rn53themes-net-poppins-semibold-16-line-height: 25px;
  --rn53themes-net-poppins-semibold-18-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-semibold-18-font-size: 18px;
  --rn53themes-net-poppins-semibold-18-font-style: normal;
  --rn53themes-net-poppins-semibold-18-font-weight: 600;
  --rn53themes-net-poppins-semibold-18-letter-spacing: 0px;
  --rn53themes-net-poppins-semibold-18-line-height: 21.600000381469727px;
  --rn53themes-net-poppins-semibold-20-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-semibold-20-font-size: 20px;
  --rn53themes-net-poppins-semibold-20-font-style: normal;
  --rn53themes-net-poppins-semibold-20-font-weight: 600;
  --rn53themes-net-poppins-semibold-20-letter-spacing: 0px;
  --rn53themes-net-poppins-semibold-20-line-height: 32px;
  --rn53themesnetblack-43: rgba(0, 0, 0, 0.43);
  --rn53themesnetblack-58: rgba(0, 0, 0, 0.58);
  --rn53themesnetblack-haze: rgba(250, 251, 251, 1);
  --rn53themesnetblack-russian: rgba(255, 0, 12, 1);
  --rn53themesnetbrick-red: rgba(203, 43, 94, 1);
  --rn53themesnetbright-turquoise: rgba(255, 230, 209, 1);
  --rn53themesnetcyan-aqua: rgba(255, 252, 240, 1);
  --rn53themesnetdark-blue: rgba(11, 41, 203, 1);
  --rn53themesnetebony-clay: rgba(32, 43, 58, 1);
  --rn53themesnethawkes-blue: rgba(205, 222, 252, 1);
  --rn53themesnetirish-coffee: rgba(88, 77, 34, 1);
  --rn53themesnetjapanese-laurel-52: rgba(255, 153, 0, 0.52);
  --rn53themesnetjust-right: rgba(234, 208, 176, 1);
  --rn53themesnetmidnight-blue: rgba(255, 22, 108, 1);
  --rn53themesnetmine-shaft: rgba(51, 51, 51, 1);
  --rn53themesnetnavy-blue: rgba(0, 255, 125, 1);
  --rn53themesnetnero-1: rgba(255, 255, 255, 0.01);
  --rn53themesnetnero-19: rgba(255, 255, 255, 0.19);
  --rn53themesnetnero-55: rgba(255, 255, 255, 0.55);
  --rn53themesnetnero-linear-bianca-cyanaqua: rgba(255, 255, 255, 1);
  --rn53themesnetred-ribbon: rgba(239, 1, 70, 1);
  --rn53themesnetrio-grande: rgba(189, 204, 255, 1);
  --rn53themesnetscarpa-flow: rgba(89, 89, 93, 1);
  --rn53themesnetwest-coast: rgba(102, 69, 28, 1);
  --rn53themesnetwild-sand: rgba(247, 247, 247, 1);
  --rn53themesnetyellow-sea: rgba(246, 175, 4, 1);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-content{
  margin-top: 50px;
}
@media (max-width: 768px) {
  .main-content{
    margin-top: 56px;
  }
}
