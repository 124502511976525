
.userDashboard {
    background-color: #211d18;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
  }
  .profileViewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 40px;
    width: 80%;
  }
  
  .headerSection {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
    align-items: flex-start;
  }
  
  .profileViewingText {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    text-align: left;
    width: 100%;
    text-decoration: none;
  }
  
  .actionIcons {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  
  .iconButton {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .iconButton img {
    width: 24px;
    height: 24px;
  }
  

  
  .actionsContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  
  .print, .share {
    border: none;
    padding: 12px 12px;
    border-radius: 15px;
    display: inline-flex;
    align-items: center;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .print{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #BDCCFF;
  }
.share{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #C0FFBD;
}
  .requestChat {
    color: #fff;
    display: inline-flex;
    padding: 7px 18px 8px 12px;
    align-items: center;
    gap: 13px;
    border-radius: 10px;
    background: #211D18;
    border: none;
  }
  
  .interested {
    background: #211D18;
    color: #fff;
    display: inline-flex;
    padding: 7px 18px 8px 12px;
    align-items: center;
    gap: 13px;
    border-radius: 10px;
    border: none;
  }
  
  .notInterested {
    background: #211D18;
    color: #fff;
    display: inline-flex;
    padding: 7px 18px 8px 12px;
    align-items: center;
    gap: 13px;
    border-radius: 10px;
    border: none;
  }
  .biopreview{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
  }
    
  @media (max-width: 768px) {
    .profileViewContainer{
      width: 100%;
    }
    .profileViewingText{
      text-align: center;
    }
    .headerSection {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 20px;
      align-items: flex-start;
    }

    .actionIcons{
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }
    }


    /* Modal Overlay */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

/* Modal Content */
.modalContent {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  position: relative;
  animation: slideDown 0.3s ease-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Close Button */
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  transition: color 0.3s;
}

.closeButton:hover {
  color: #333;
}

/* Modal Text */
.modalContent p {
  font-size: 16px;
  margin: 20px 0;
  color: #444;
}

/* Modal Actions */
.modalActions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

/* Yes Button */
.yesButton {
  background-color: #ff5c5c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.yesButton:hover {
  background-color: #e04444;
}

/* No Button */
.noButton {
  background-color: #ddd;
  color: #444;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.noButton:hover {
  background-color: #ccc;
}

/* Animations */
@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideDown {
  from {
      transform: translateY(-20px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}
