.meetFounderSection {
  background-color: #fff;
  overflow: hidden;
}

.contentWrapper {
  display: flex;
  gap: 50px;
  justify-content: space-between;
}

.textColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin-left: 40px;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: #bf5a5a;
  font: 700 30px Playfair Display, -apple-system, Roboto, Helvetica, sans-serif;
  margin: 110px 30px 0 35px;
}

.title {
  align-self: flex-start;
  margin-left: 10px;
}

.decorativeBorder {
  border-radius: 20px;
  display: flex;
  margin: 5px 0 0 -1px;
  border: 2px solid #8f2c5a;
}
.founderText{
  color: #BC6868;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  padding: 13px;
}
.imageColumn {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  line-height: normal;
  margin-left: 20px;
  position: relative;
  align-items: center;
}
.founderContainer{
position: relative;
}
.founderImage {
  aspect-ratio: 0.83;
  object-fit: contain;
  object-position: center;
  transition: transform 0.3s ease-in-out;
  position: relative;
  width: 558px;
}

.founderTopImage{
  position: absolute;
  top: 58px;
  left: 50%;
  transform: translateX(-50%); 
  z-index: 1;
}
.namecardTextContainer{
  text-align: left;
  color: var(--Neutrals-Colors-100, #FFF);
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 17px 73px;
}
.founderName{
  font-family: "Playfair Display";
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.namecardImage {
  background-image: url('/assets/namecard.svg');
  background-size: contain; 
  background-repeat: no-repeat; 
  background-position: center; 
  position: absolute;
  top: 449px;
  right: 239px;
  width: 100%;
  height: 148px; 
  z-index: 0;
  text-align: left;
}

.namecardbottomImage{
    position: absolute;
    top: 569px;
    left: -238px;
  }
  .socialLinks {
    position: absolute;
    border-radius: 15px;
    background: rgba(217, 217, 217, 0.70);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 299px;
    bottom: 77px;
    z-index: 10;
    left: 148px;
    gap: 5px; 
  }
  
  .iconLink {
    padding: 9px 11px;
    border-radius: 14px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
  
  .iconLink svg {
    color: black; /* Default black icons */
    width: 18px;
    height: 18px;
    transition: transform 0.4s ease, box-shadow 0.4s ease, color 0.4s ease;
  }
  
  .iconLink:hover svg {
    transform: scale(1.2);
    color: #fff; /* Icons will turn white on hover */
  }
  
  .iconLink::before {
    content: "";
    position: absolute;
    width: 130%;
    height: 130%;
    left: -110%;
    top: 80%;
    transform: rotate(45deg);
  }
  
  .iconLink:hover::before {
    animation: slide 0.7s forwards;
  }
  
  @keyframes slide {
    50% {
      left: 10%;
      top: -40%;
    }
    100% {
      left: -15%;
      top: -15%;
    }
  }
  
  /* Specific colors for each hover effect */
  .iconLink.facebook:hover::before {
    background: #3b5998;
  }
  .iconLink.instagram:hover::before {
    background: linear-gradient(45deg, #f9ce34, #ee2a7b, #6228d7);
  }
  .iconLink.twitter:hover::before {
    background: #1da1f2;
  }
  .iconLink.whatsapp:hover::before {
    background: #25d366;
  }
  .iconLink.linkedin:hover::before {
    background: #0077b5;
  }
  .iconLink.youtube:hover::before {
    background: #ff0000;
  }
  


@media (max-width: 768px) {
  .titleWrapper{
    font-size: 20px;
  }
  .founderImage{
      width: 383px;
  }
  .founderText{
    font-size: 14px;
  }
  .founderTopImage{
    top: 0;
    width: 289px;
  }

  .namecardImage{
    top: 360px;
    right: 0px;
  }
  .founderName{
    font-size: 18px;
  }
  .namecardTextContainer{
    padding: 34px 73px;
    font-size: 12px;
  }
  .namecardbottomImage{
    top: 464px;
    left: 0px;
  }
  .imageColumn{
    margin-bottom: 100px;
    margin-left: 0px;
  }
  .socialLinks{
    bottom: 85px;
    left: 60px;
  }
  .contentWrapper {
    flex-direction: column;
    gap: 0;
  }
 .textColumn{
  margin-left: 0px;
 }

}

