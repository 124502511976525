.userDashboard {
    background-color: #211d18;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .dashboardContent {
    border-radius: 15px;
    background-color: #fff;
    align-self: center;
    display: flex;
    margin: 55px;
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
    overflow: hidden;
    padding: 79px 70px;
  }
  
  .profileSection {
    width: 956px;
    max-width: 100%;
  }
  
  .profileLayout {
    gap: 20px;
    display: flex;
  }
  
  .profileImageColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 33%;
    margin-left: 0;
  }
  
  .profileImage {
    border-radius: 15px;
    background-color: #100000;
    display: flex;
    width: 300px;
    height: 300px;
    aspect-ratio: 1;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .logoutButton {
    background-color: #610d0d;
    justify-content: center;
  }
  
  .logoutButton:hover {
    background-color: #ac0b0b; 
    color: #F1E4E8;
  }
  
  .profileInfoColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 67%;
    margin-left: 20px;
  }
  
  .profileInfo {
    display: flex;
    margin-top: 77px;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    font-family: Poppins, sans-serif;
    color: #000;
  }
  
  .profileName {
    font-size: 35px;
    font-weight: 700;
  }
  
  .profileDetails {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    font-size: 20px;
    font-weight: 300;
    align-self: stretch;
    margin-top: 15px;
    text-align: left;
    font-family: Poppins;
  }
  
  .editButton {
    border-radius: 10px;
    background-color: #000;
    margin-top: 26px;
    overflow: hidden;
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    padding: 6px 54px;
    cursor: pointer;
  }
  
  .actionSection {
    align-self: end;
    display: flex;
    margin-top: 61px;
    max-width: 100%;
    gap: 40px 58px;
    color: #fff;
    white-space: nowrap;
    flex-wrap: wrap;
    font: 700 30px Poppins, sans-serif;
  }
  
  .actionButton {
    color: #fff;
    border-radius: 15px;
    display: flex;
    gap: 40px 91px;
    overflow: hidden;
    flex: 1;
    flex-basis: auto;
    padding: 9px 25px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .actionButtonContent {
    display: flex;
    gap: 38px;
  }
  
  .actionIcon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 50px;
    align-self: flex-start;
  }
  
  .actionText {
    flex-basis: auto;
  }
  
  .matchButton {
    background-color: #171717;
  }
  
  .supportButton {
    background-color: #5671d0;
  }
  
  @media (max-width: 991px) {
    .dashboardContent {
      max-width: 100%;
      margin-top: 40px;
      padding: 40px 20px;
    }
  
    .profileLayout {
      flex-direction: column;
      align-items: stretch;
      gap: 40px;
    }
  
    .profileImageColumn,
    .profileInfoColumn {
      width: 100%;
      margin-left: 0;
    }
  
    .profileImage {
      margin-top: 0;
    }
  
    .profileInfo {
      margin: 30px 39px;
    }
  
    .profileDetails {
      max-width: 100%;
    }
  
    .editButton {
      align-self: center;
      padding: 10px 20px;
    }
  
    .actionSection {
      margin: 40px;
      white-space: initial;
      justify-content: center;
      font-size: 30px;
    }
    .actionIcon{
      width: 40px;
    }
    .actionButton {
      padding: 15px 20px;
      white-space: initial;
      width: 60%;
    }
  
    .actionButtonContent {
      justify-content: center;
    }
  }