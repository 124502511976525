.report-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s forwards;
  }
  
  .report-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: slideIn 0.3s forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-20px);
    }
  }
  
  .fade-out {
    animation: fadeOut 0.3s forwards;
  }
  
  .slide-out {
    animation: slideOut 0.3s forwards;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: #555;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  select,
textarea,
input[type="file"] {
  width: 100%; /* Set inputs to 100% width */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding doesn't affect total width */
}

textarea {
  resize: vertical; /* Allow vertical resizing only */
}
  
  .form-group select:focus,
  .form-group textarea:focus,
  .form-group input:focus {
    border-color: #007BFF;
  }
  
  .image-preview {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .image-preview-item {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .image-preview-item img {
    max-width: 100px;
    max-height: 100px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .remove-image-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 0, 0, 0.8);
    border: none;
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    font-size: 1.2em;
    text-align: center;
    line-height: 25px;
  }
  
  .submit-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  