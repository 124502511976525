/* FamilyInformationForm.module.css */

.container {
    width: 80%;
    margin: 5rem auto;
    font-family: Nunito;
  }
  .title{
    padding: 10px 0;
    color: #484848;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .fieldRow {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }

  .fieldColumn {
    flex: 1;
    min-width: 250px;
  }
  
  .fieldLabel {
    display: block;
    margin-bottom: 0.5rem;
    color: #484848;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .textField  {
    width: 100%;
    padding: 0.638rem;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box; 
  }
  
  .textField:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: space-between;
  }
  
  .button {
    padding: 0.75rem 4rem;
    background-color: #070e16;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .button:hover {
    background-color: #1b1b28;
  }
  @media (max-width: 768px){
    .button {
      padding: 0.75rem 2rem;
    }

  }