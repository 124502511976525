
.container {
  background: #211D18;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.topCoverContainer{
  position: relative;
}
.top_cover {
  border-radius: 0 0 40px 40px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-height: 580px;
  z-index: 0;
}
.lotapata{
  position: absolute;
  bottom: -83px;
  left: 13px;
  width: 250px;
  opacity: 0.5;
}
.venusrings{
  position: absolute;
  bottom: -98px;
  right: 18px;
  width: 250px;
}
.hero {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  margin-bottom: 37px;
}


.heroTitle {
  border-radius: 15px;
  background: linear-gradient(90deg, #FFC05B 0%, #FB9D04 100%);
  color: #000;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 30px 50px;
  margin-top: -50px;
  z-index: 999;
}

.whatVenus{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.sectionTitle {
  align-self: start;
  color: #EA2F6F;
  text-align: right;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  margin: 30px 0 15px 0;
}

.sectionTitleText {
  flex-grow: 1;
  margin: auto 0;
}

.sectionTitleIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 30px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.descriptionSection {
  align-self: center;
  z-index: 10;
  display: flex;
  width: 100%;
  max-width: 1075px;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  text-align: center;
}

.description {
  border-radius: 15px;
  background: linear-gradient(90deg, #FFC05B 0%, #FB9D04 100%); 
  color: #1C1B1F;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 40px;
}


.benefits {
  border-radius: 15px;
  background: linear-gradient(90deg, #FFC05B 0%, #FB9D04 100%); 
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 40px;
}


.contactInfo {
  background-color: #ea2f6f;
  z-index: 10;
  margin-top: 33px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  padding: 10px;
  font: 400 21px Inter, sans-serif;
}

.contactInfoBold {
  font-weight: 500;
}

.howvenuscontainer {
  background: #211D18;
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  padding-top: 80px;
}

.closeIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainTitle {
  color: var(--Cards-Backgrounds-BG-Color-1, #FFF);
  text-align: center;
  font: 600 40px Playfair Display, -apple-system, Roboto, Helvetica, sans-serif;
  margin: 0;
}

.logoContainer {
  align-self: center;
  display: flex;
}

.logo1 {
  aspect-ratio: 1.69;
  object-fit: contain;
  object-position: center;
  width: 240px;
}

.stepsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.stepsWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.stepsList {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.verticalDivider {
  background: var(--rn-53-themes-net-bone, #ddcebc);
  position: absolute;
  z-index: 0;
  display: flex;
  min-height: 1370px;
  width: 1px;
  right: 50%;
  top: 0px;
  bottom: 0px;
}

.stepItem {
  z-index: 0;
  display: flex;
  width: 100%;
  padding-bottom: 50px;
  flex-direction: column;
  justify-content: flex-start;
}

.stepContent {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10%;
}

.stepImageContainer {
  display: flex;
  width: 34%;
  align-items: flex-start;
  justify-content: flex-end;
}

.stepImage {
  aspect-ratio: 1;
  object-position: center;
  width: 128px;
  max-width: 450px;
}

.stepTextContainer {
  display: flex;
  width: 34%;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  color: var(--Cards-Backgrounds-BG-Color-1, #FFF);
  justify-content: flex-start;
  text-align: left;
}

.stepTitle {
  color: var(--Cards-Backgrounds-BG-Color-1, #FFF);
  font-family: "Playfair Display";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
}

.stepSubtitle {
  color: var(--rn-53-themes-net-razzmatazz, #eb0359);
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 11px;
}

.stepDescription {
  margin-top: 11px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  padding: 10px 0 15px;
}

.stepItemReverse .stepTextContainer {
  text-align: right;
}

.stepItemReverse .stepSubtitle {
  align-self: flex-end;
}

.stepItemReverse .stepImageContainer {
  justify-content: flex-start;
}

.backgroundBorderShadow {
  border-radius: 50px;
  border: 4px solid var(--rn-53-themes-net-nero, #fff);
  background: var(--rn-53-themes-net-west-coast, #66451c);
  box-shadow: 0px 0px 10px 0.6px rgba(40, 30, 20, 0.08);
  position: absolute;
  z-index: 0;
  display: flex;
  width: 17px;
  height: 17px;
    /* Centering the element horizontally */
    left: 50%;
    transform: translateX(-50%);
}

/* Responsive Styles for Mobile */

@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }

  .hero {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }


  .heroTitle {
    max-width: 100%;
    font-size: 16px;
    padding: 15px;
    margin: -50px 1.5rem 1.5rem 1.5rem;
  }
  .whatVenus{
    margin: 0 1.5rem;
  }
  .whatExpect{
    margin: 0 1.5rem;
  }
  .sectionTitle {
    margin-top: 20px;
  }
  .sectionTitleText{
    font-size: 16px;
  }
  .descriptionSection {
    max-width: 100%;
  }

  .description {
    max-width: 100%;
    padding: 20px;
    font-size: 15px;
  }

  .benefits {
    max-width: 100%;
    padding: 20px;
    font-size: 15px;
  }

  .howvenuscontainer{
    padding-top: 50px;
  }
  .mainTitle{
    font-size: 20px;
  }
  .logo1{width: 160px;}
  .stepImageContainer {
      width: 42%;
    }
  .stepTextContainer{
    width: 42%;
  }
  .stepTitle{
    font-size: 18px;
    line-height: normal;
  }
  .stepSubtitle{
    font-size: 8px;
    line-height: normal;
  }
  .stepDescription{
    font-size: 10px;
    line-height: normal;
  }
  .contactInfo{
    font-size: 16px;
  }
}