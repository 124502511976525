@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playball:wght@400&display=swap);
body {

  font-family: -apple-system, BlinkMacSystemFont, 'Poppins','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
}
*{
  margin: 0;
  padding: 0;
}
:root {
  --preview-themeforest-net-font-awesome-5-free-solid-14-font-family: "Font Awesome 5 Free", Helvetica;
  --preview-themeforest-net-font-awesome-5-free-solid-14-font-size: 14px;
  --preview-themeforest-net-font-awesome-5-free-solid-14-font-style: normal;
  --preview-themeforest-net-font-awesome-5-free-solid-14-font-weight: 400;
  --preview-themeforest-net-font-awesome-5-free-solid-14-letter-spacing: 0px;
  --preview-themeforest-net-font-awesome-5-free-solid-14-line-height: 14px;
  --preview-themeforest-net-IBM-plex-sans-regular-14-font-family: "IBM Plex Sans", Helvetica;
  --preview-themeforest-net-IBM-plex-sans-regular-14-font-size: 14px;
  --preview-themeforest-net-IBM-plex-sans-regular-14-font-style: normal;
  --preview-themeforest-net-IBM-plex-sans-regular-14-font-weight: 400;
  --preview-themeforest-net-IBM-plex-sans-regular-14-letter-spacing: 0px;
  --preview-themeforest-net-IBM-plex-sans-regular-14-line-height: normal;
  --preview-themeforest-net-inter-regular-11-25-underline-font-family: "Inter", Helvetica;
  --preview-themeforest-net-inter-regular-11-25-underline-font-size: 11.25px;
  --preview-themeforest-net-inter-regular-11-25-underline-font-style: normal;
  --preview-themeforest-net-inter-regular-11-25-underline-font-weight: 400;
  --preview-themeforest-net-inter-regular-11-25-underline-letter-spacing: 0px;
  --preview-themeforest-net-inter-regular-11-25-underline-line-height: 54px;
  --preview-themeforest-net-inter-regular-13-23-font-family: "Inter", Helvetica;
  --preview-themeforest-net-inter-regular-13-23-font-size: 13.234375px;
  --preview-themeforest-net-inter-regular-13-23-font-style: normal;
  --preview-themeforest-net-inter-regular-13-23-font-weight: 400;
  --preview-themeforest-net-inter-regular-13-23-letter-spacing: 0px;
  --preview-themeforest-net-inter-regular-13-23-line-height: 21px;
  --preview-themeforest-net-poppins-extrabold-26-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-extrabold-26-font-size: 26px;
  --preview-themeforest-net-poppins-extrabold-26-font-style: normal;
  --preview-themeforest-net-poppins-extrabold-26-font-weight: 800;
  --preview-themeforest-net-poppins-extrabold-26-letter-spacing: 0px;
  --preview-themeforest-net-poppins-extrabold-26-line-height: 31.200000762939453px;
  --preview-themeforest-net-poppins-light-16-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-light-16-font-size: 16px;
  --preview-themeforest-net-poppins-light-16-font-style: normal;
  --preview-themeforest-net-poppins-light-16-font-weight: 300;
  --preview-themeforest-net-poppins-light-16-letter-spacing: 0px;
  --preview-themeforest-net-poppins-light-16-line-height: normal;
  --preview-themeforest-net-poppins-light-italic-16-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-light-italic-16-font-size: 16px;
  --preview-themeforest-net-poppins-light-italic-16-font-style: italic;
  --preview-themeforest-net-poppins-light-italic-16-font-weight: 300;
  --preview-themeforest-net-poppins-light-italic-16-letter-spacing: 0px;
  --preview-themeforest-net-poppins-light-italic-16-line-height: 19.200000762939453px;
  --preview-themeforest-net-poppins-medium-13-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-medium-13-font-size: 13px;
  --preview-themeforest-net-poppins-medium-13-font-style: normal;
  --preview-themeforest-net-poppins-medium-13-font-weight: 500;
  --preview-themeforest-net-poppins-medium-13-letter-spacing: 0px;
  --preview-themeforest-net-poppins-medium-13-line-height: 15.600000381469727px;
  --preview-themeforest-net-poppins-medium-15-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-medium-15-font-size: 15px;
  --preview-themeforest-net-poppins-medium-15-font-style: normal;
  --preview-themeforest-net-poppins-medium-15-font-weight: 500;
  --preview-themeforest-net-poppins-medium-15-letter-spacing: 0px;
  --preview-themeforest-net-poppins-medium-15-line-height: 18px;
  --preview-themeforest-net-poppins-medium-16-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-medium-16-font-size: 16px;
  --preview-themeforest-net-poppins-medium-16-font-style: normal;
  --preview-themeforest-net-poppins-medium-16-font-weight: 500;
  --preview-themeforest-net-poppins-medium-16-letter-spacing: 0px;
  --preview-themeforest-net-poppins-medium-16-line-height: 24px;
  --preview-themeforest-net-poppins-regular-15-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-regular-15-font-size: 15px;
  --preview-themeforest-net-poppins-regular-15-font-style: normal;
  --preview-themeforest-net-poppins-regular-15-font-weight: 400;
  --preview-themeforest-net-poppins-regular-15-letter-spacing: 0px;
  --preview-themeforest-net-poppins-regular-15-line-height: 18px;
  --preview-themeforest-net-poppins-regular-16-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-regular-16-font-size: 16px;
  --preview-themeforest-net-poppins-regular-16-font-style: normal;
  --preview-themeforest-net-poppins-regular-16-font-weight: 400;
  --preview-themeforest-net-poppins-regular-16-letter-spacing: 0px;
  --preview-themeforest-net-poppins-regular-16-line-height: 19.200000762939453px;
  --preview-themeforest-net-poppins-semibold-70-font-family: "Poppins", Helvetica;
  --preview-themeforest-net-poppins-semibold-70-font-size: 70px;
  --preview-themeforest-net-poppins-semibold-70-font-style: normal;
  --preview-themeforest-net-poppins-semibold-70-font-weight: 600;
  --preview-themeforest-net-poppins-semibold-70-letter-spacing: 0px;
  --preview-themeforest-net-poppins-semibold-70-line-height: 84px;
  --preview-themeforest-net-prociono-regular-18-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-18-font-size: 18px;
  --preview-themeforest-net-prociono-regular-18-font-style: normal;
  --preview-themeforest-net-prociono-regular-18-font-weight: 400;
  --preview-themeforest-net-prociono-regular-18-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-18-line-height: 21.600000381469727px;
  --preview-themeforest-net-prociono-regular-19-84-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-19-84-font-size: 19.84375px;
  --preview-themeforest-net-prociono-regular-19-84-font-style: normal;
  --preview-themeforest-net-prociono-regular-19-84-font-weight: 400;
  --preview-themeforest-net-prociono-regular-19-84-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-19-84-line-height: 24px;
  --preview-themeforest-net-prociono-regular-20-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-20-font-size: 20px;
  --preview-themeforest-net-prociono-regular-20-font-style: normal;
  --preview-themeforest-net-prociono-regular-20-font-weight: 400;
  --preview-themeforest-net-prociono-regular-20-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-20-line-height: 24px;
  --preview-themeforest-net-prociono-regular-22-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-22-font-size: 22px;
  --preview-themeforest-net-prociono-regular-22-font-style: normal;
  --preview-themeforest-net-prociono-regular-22-font-weight: 400;
  --preview-themeforest-net-prociono-regular-22-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-22-line-height: 26.399999618530273px;
  --preview-themeforest-net-prociono-regular-48-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-48-font-size: 48px;
  --preview-themeforest-net-prociono-regular-48-font-style: normal;
  --preview-themeforest-net-prociono-regular-48-font-weight: 400;
  --preview-themeforest-net-prociono-regular-48-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-48-line-height: 57.599998474121094px;
  --preview-themeforest-net-prociono-regular-65-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-65-font-size: 65px;
  --preview-themeforest-net-prociono-regular-65-font-style: normal;
  --preview-themeforest-net-prociono-regular-65-font-weight: 400;
  --preview-themeforest-net-prociono-regular-65-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-65-line-height: 78px;
  --preview-themeforest-net-prociono-regular-70-font-family: "Prociono", Helvetica;
  --preview-themeforest-net-prociono-regular-70-font-size: 70px;
  --preview-themeforest-net-prociono-regular-70-font-style: normal;
  --preview-themeforest-net-prociono-regular-70-font-weight: 400;
  --preview-themeforest-net-prociono-regular-70-letter-spacing: 0px;
  --preview-themeforest-net-prociono-regular-70-line-height: 84px;
  --previewthemeforestnetblack: rgba(0, 0, 0, 1);
  --previewthemeforestnetblack-50: rgba(0, 0, 0, 0.5);
  --previewthemeforestnetblack-52: rgba(0, 0, 0, 0.52);
  --previewthemeforestnetblack-66: rgba(0, 0, 0, 0.66);
  --previewthemeforestnetblue: rgba(0, 0, 238, 1);
  --previewthemeforestnetcod-gray: rgba(17, 17, 17, 1);
  --previewthemeforestnetdeep-bronze: rgba(81, 54, 6, 1);
  --previewthemeforestnetdove-gray: rgba(114, 110, 110, 1);
  --previewthemeforestnetivory: rgba(248, 243, 243, 1);
  --previewthemeforestnetmine-shaft: rgba(38, 38, 38, 1);
  --previewthemeforestnetold-lace: rgba(253, 243, 226, 1);
  --previewthemeforestnetoslo-gray: rgba(135, 137, 145, 1);
  --previewthemeforestnetsanta-fe: rgba(169, 116, 83, 1);
  --previewthemeforestnetsushi: rgba(130, 180, 64, 1);
  --previewthemeforestnetwhite: rgba(255, 255, 255, 1);
  --previewthemeforestnetwhite-02: rgba(255, 255, 255, 0);
  --previewthemeforestnetwhite-32: rgba(255, 255, 255, 0.32);
  --previewthemeforestnetwhite-black: rgba(255, 255, 255, 1);
  --rn-5-3themesnetalto: rgba(217, 217, 217, 1);
  --rn-5-3themesnetblack: rgba(0, 0, 0, 1);
  --rn-5-3themesnetbone: rgba(221, 206, 188, 1);
  --rn-5-3themesnetboulder: rgba(122, 122, 122, 1);
  --rn-5-3themesnetemperor: rgba(85, 85, 85, 1);
  --rn-5-3themesnetfiord: rgba(65, 87, 100, 1);
  --rn-5-3themesnetgeraldine: rgba(253, 134, 134, 1);
  --rn-5-3themesnetgreen: rgba(255, 180, 0, 1);
  --rn-5-3themesnetheather: rgba(185, 198, 207, 1);
  --rn-5-3themesnetlynch: rgba(96, 125, 139, 1);
  --rn-5-3themesnetmercury: rgba(231, 231, 231, 1);
  --rn-5-3themesnetmerino: rgba(243, 238, 220, 1);
  --rn-5-3themesnetmongoose: rgba(183, 155, 121, 1);
  --rn-5-3themesnetmystic: rgba(219, 229, 234, 1);
  --rn-5-3themesnetnero: rgba(255, 255, 255, 1);
  --rn-5-3themesnetrazzmatazz: rgba(235, 3, 89, 1);
  --rn-5-3themesnetred: rgba(236, 255, 0, 1);
  --rn-5-3themesnetsandstone: rgba(116, 106, 99, 1);
  --rn-5-3themesnetsisal: rgba(215, 209, 190, 1);
  --rn-5-3themesnettussock: rgba(196, 140, 70, 1);
  --rn-5-3themesnetvictoria: rgba(75, 62, 126, 1);
  --rn-5-3themesnetwafer: rgba(219, 204, 187, 1);
  --rn53themes-net-cinzel-decorative-bold-17-72-font-family: "Cinzel Decorative", Helvetica;
  --rn53themes-net-cinzel-decorative-bold-17-72-font-size: 17.71875px;
  --rn53themes-net-cinzel-decorative-bold-17-72-font-style: normal;
  --rn53themes-net-cinzel-decorative-bold-17-72-font-weight: 700;
  --rn53themes-net-cinzel-decorative-bold-17-72-letter-spacing: 0px;
  --rn53themes-net-cinzel-decorative-bold-17-72-line-height: 21.600000381469727px;
  --rn53themes-net-cinzel-decorative-bold-20-upper-font-family: "Cinzel Decorative", Helvetica;
  --rn53themes-net-cinzel-decorative-bold-20-upper-font-size: 20px;
  --rn53themes-net-cinzel-decorative-bold-20-upper-font-style: normal;
  --rn53themes-net-cinzel-decorative-bold-20-upper-font-weight: 700;
  --rn53themes-net-cinzel-decorative-bold-20-upper-letter-spacing: 5px;
  --rn53themes-net-cinzel-decorative-bold-20-upper-line-height: 30px;
  --rn53themes-net-cinzel-decorative-bold-35-font-family: "Cinzel Decorative", Helvetica;
  --rn53themes-net-cinzel-decorative-bold-35-font-size: 35px;
  --rn53themes-net-cinzel-decorative-bold-35-font-style: normal;
  --rn53themes-net-cinzel-decorative-bold-35-font-weight: 700;
  --rn53themes-net-cinzel-decorative-bold-35-letter-spacing: 0px;
  --rn53themes-net-cinzel-decorative-bold-35-line-height: 78px;
  --rn53themes-net-cinzel-decorative-bold-40-font-family: "Cinzel Decorative", Helvetica;
  --rn53themes-net-cinzel-decorative-bold-40-font-size: 40px;
  --rn53themes-net-cinzel-decorative-bold-40-font-style: normal;
  --rn53themes-net-cinzel-decorative-bold-40-font-weight: 700;
  --rn53themes-net-cinzel-decorative-bold-40-letter-spacing: 0px;
  --rn53themes-net-cinzel-decorative-bold-40-line-height: 48px;
  --rn53themes-net-cinzel-decorative-bold-58-font-family: "Cinzel Decorative", Helvetica;
  --rn53themes-net-cinzel-decorative-bold-58-font-size: 58px;
  --rn53themes-net-cinzel-decorative-bold-58-font-style: normal;
  --rn53themes-net-cinzel-decorative-bold-58-font-weight: 700;
  --rn53themes-net-cinzel-decorative-bold-58-letter-spacing: 0px;
  --rn53themes-net-cinzel-decorative-bold-58-line-height: 44px;
  --rn53themes-net-cinzel-decorative-regular-19-font-family: "Cinzel Decorative", Helvetica;
  --rn53themes-net-cinzel-decorative-regular-19-font-size: 19px;
  --rn53themes-net-cinzel-decorative-regular-19-font-style: normal;
  --rn53themes-net-cinzel-decorative-regular-19-font-weight: 400;
  --rn53themes-net-cinzel-decorative-regular-19-letter-spacing: 0px;
  --rn53themes-net-cinzel-decorative-regular-19-line-height: 28px;
  --rn53themes-net-josefin-sans-regular-16-font-family: "Josefin Sans", Helvetica;
  --rn53themes-net-josefin-sans-regular-16-font-size: 16px;
  --rn53themes-net-josefin-sans-regular-16-font-style: normal;
  --rn53themes-net-josefin-sans-regular-16-font-weight: 400;
  --rn53themes-net-josefin-sans-regular-16-letter-spacing: 0px;
  --rn53themes-net-josefin-sans-regular-16-line-height: 24px;
  --rn53themes-net-josefin-sans-semibold-16-upper-font-family: "Josefin Sans", Helvetica;
  --rn53themes-net-josefin-sans-semibold-16-upper-font-size: 16px;
  --rn53themes-net-josefin-sans-semibold-16-upper-font-style: normal;
  --rn53themes-net-josefin-sans-semibold-16-upper-font-weight: 600;
  --rn53themes-net-josefin-sans-semibold-16-upper-letter-spacing: 0px;
  --rn53themes-net-josefin-sans-semibold-16-upper-line-height: 28px;
  --rn53themes-net-josefin-sans-semibold-18-font-family: "Josefin Sans", Helvetica;
  --rn53themes-net-josefin-sans-semibold-18-font-size: 18px;
  --rn53themes-net-josefin-sans-semibold-18-font-style: normal;
  --rn53themes-net-josefin-sans-semibold-18-font-weight: 600;
  --rn53themes-net-josefin-sans-semibold-18-letter-spacing: 1px;
  --rn53themes-net-josefin-sans-semibold-18-line-height: 35px;
  --rn53themes-net-playfair-display-bold-32-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-bold-32-font-size: 32px;
  --rn53themes-net-playfair-display-bold-32-font-style: normal;
  --rn53themes-net-playfair-display-bold-32-font-weight: 700;
  --rn53themes-net-playfair-display-bold-32-letter-spacing: 0px;
  --rn53themes-net-playfair-display-bold-32-line-height: 36px;
  --rn53themes-net-playfair-display-bold-65-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-bold-65-font-size: 65px;
  --rn53themes-net-playfair-display-bold-65-font-style: normal;
  --rn53themes-net-playfair-display-bold-65-font-weight: 700;
  --rn53themes-net-playfair-display-bold-65-letter-spacing: 0px;
  --rn53themes-net-playfair-display-bold-65-line-height: 70px;
  --rn53themes-net-playfair-display-bold-72-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-bold-72-font-size: 72px;
  --rn53themes-net-playfair-display-bold-72-font-style: normal;
  --rn53themes-net-playfair-display-bold-72-font-weight: 700;
  --rn53themes-net-playfair-display-bold-72-letter-spacing: 0px;
  --rn53themes-net-playfair-display-bold-72-line-height: 70px;
  --rn53themes-net-playfair-display-extrabold-80-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-extrabold-80-font-size: 80px;
  --rn53themes-net-playfair-display-extrabold-80-font-style: normal;
  --rn53themes-net-playfair-display-extrabold-80-font-weight: 800;
  --rn53themes-net-playfair-display-extrabold-80-letter-spacing: 0px;
  --rn53themes-net-playfair-display-extrabold-80-line-height: 100px;
  --rn53themes-net-playfair-display-medium-24-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-medium-24-font-size: 24px;
  --rn53themes-net-playfair-display-medium-24-font-style: normal;
  --rn53themes-net-playfair-display-medium-24-font-weight: 500;
  --rn53themes-net-playfair-display-medium-24-letter-spacing: 0px;
  --rn53themes-net-playfair-display-medium-24-line-height: 28.799999237060547px;
  --rn53themes-net-playfair-display-medium-26-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-medium-26-font-size: 26px;
  --rn53themes-net-playfair-display-medium-26-font-style: normal;
  --rn53themes-net-playfair-display-medium-26-font-weight: 500;
  --rn53themes-net-playfair-display-medium-26-letter-spacing: 0px;
  --rn53themes-net-playfair-display-medium-26-line-height: 31.200000762939453px;
  --rn53themes-net-playfair-display-medium-30-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-medium-30-font-size: 30px;
  --rn53themes-net-playfair-display-medium-30-font-style: normal;
  --rn53themes-net-playfair-display-medium-30-font-weight: 500;
  --rn53themes-net-playfair-display-medium-30-letter-spacing: 0px;
  --rn53themes-net-playfair-display-medium-30-line-height: 36px;
  --rn53themes-net-playfair-display-regular-22-upper-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-regular-22-upper-font-size: 22px;
  --rn53themes-net-playfair-display-regular-22-upper-font-style: normal;
  --rn53themes-net-playfair-display-regular-22-upper-font-weight: 400;
  --rn53themes-net-playfair-display-regular-22-upper-letter-spacing: 0px;
  --rn53themes-net-playfair-display-regular-22-upper-line-height: 33px;
  --rn53themes-net-playfair-display-semibold-42-upper-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-semibold-42-upper-font-size: 42px;
  --rn53themes-net-playfair-display-semibold-42-upper-font-style: normal;
  --rn53themes-net-playfair-display-semibold-42-upper-font-weight: 600;
  --rn53themes-net-playfair-display-semibold-42-upper-letter-spacing: 0px;
  --rn53themes-net-playfair-display-semibold-42-upper-line-height: 63px;
  --rn53themes-net-playfair-display-semibold-46-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-semibold-46-font-size: 46px;
  --rn53themes-net-playfair-display-semibold-46-font-style: normal;
  --rn53themes-net-playfair-display-semibold-46-font-weight: 600;
  --rn53themes-net-playfair-display-semibold-46-letter-spacing: 0px;
  --rn53themes-net-playfair-display-semibold-46-line-height: 55.20000076293945px;
  --rn53themes-net-playfair-display-semibold-90-font-family: "Playfair Display", Helvetica;
  --rn53themes-net-playfair-display-semibold-90-font-size: 90px;
  --rn53themes-net-playfair-display-semibold-90-font-style: normal;
  --rn53themes-net-playfair-display-semibold-90-font-weight: 600;
  --rn53themes-net-playfair-display-semibold-90-letter-spacing: 0px;
  --rn53themes-net-playfair-display-semibold-90-line-height: 70px;
  --rn53themes-net-poppins-bold-14-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-bold-14-upper-font-size: 14px;
  --rn53themes-net-poppins-bold-14-upper-font-style: normal;
  --rn53themes-net-poppins-bold-14-upper-font-weight: 700;
  --rn53themes-net-poppins-bold-14-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-bold-14-upper-line-height: 16.799999237060547px;
  --rn53themes-net-poppins-bold-15-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-bold-15-font-size: 15px;
  --rn53themes-net-poppins-bold-15-font-style: normal;
  --rn53themes-net-poppins-bold-15-font-weight: 700;
  --rn53themes-net-poppins-bold-15-letter-spacing: 0px;
  --rn53themes-net-poppins-bold-15-line-height: 22.5px;
  --rn53themes-net-poppins-bold-16-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-bold-16-font-size: 16px;
  --rn53themes-net-poppins-bold-16-font-style: normal;
  --rn53themes-net-poppins-bold-16-font-weight: 700;
  --rn53themes-net-poppins-bold-16-letter-spacing: 0px;
  --rn53themes-net-poppins-bold-16-line-height: 15px;
  --rn53themes-net-poppins-bold-20-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-bold-20-font-size: 20px;
  --rn53themes-net-poppins-bold-20-font-style: normal;
  --rn53themes-net-poppins-bold-20-font-weight: 700;
  --rn53themes-net-poppins-bold-20-letter-spacing: 0px;
  --rn53themes-net-poppins-bold-20-line-height: 24px;
  --rn53themes-net-poppins-light-20-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-light-20-font-size: 20px;
  --rn53themes-net-poppins-light-20-font-style: normal;
  --rn53themes-net-poppins-light-20-font-weight: 300;
  --rn53themes-net-poppins-light-20-letter-spacing: 0px;
  --rn53themes-net-poppins-light-20-line-height: 28px;
  --rn53themes-net-poppins-medium-10-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-10-upper-font-size: 10px;
  --rn53themes-net-poppins-medium-10-upper-font-style: normal;
  --rn53themes-net-poppins-medium-10-upper-font-weight: 500;
  --rn53themes-net-poppins-medium-10-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-10-upper-line-height: 15px;
  --rn53themes-net-poppins-medium-11-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-11-font-size: 11px;
  --rn53themes-net-poppins-medium-11-font-style: normal;
  --rn53themes-net-poppins-medium-11-font-weight: 500;
  --rn53themes-net-poppins-medium-11-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-11-line-height: 16.5px;
  --rn53themes-net-poppins-medium-11-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-11-upper-font-size: 11px;
  --rn53themes-net-poppins-medium-11-upper-font-style: normal;
  --rn53themes-net-poppins-medium-11-upper-font-weight: 500;
  --rn53themes-net-poppins-medium-11-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-11-upper-line-height: 16.5px;
  --rn53themes-net-poppins-medium-13-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-13-font-size: 13px;
  --rn53themes-net-poppins-medium-13-font-style: normal;
  --rn53themes-net-poppins-medium-13-font-weight: 500;
  --rn53themes-net-poppins-medium-13-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-13-line-height: 22px;
  --rn53themes-net-poppins-medium-13-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-13-upper-font-size: 13px;
  --rn53themes-net-poppins-medium-13-upper-font-style: normal;
  --rn53themes-net-poppins-medium-13-upper-font-weight: 500;
  --rn53themes-net-poppins-medium-13-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-13-upper-line-height: 19.5px;
  --rn53themes-net-poppins-medium-14-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-14-font-size: 14px;
  --rn53themes-net-poppins-medium-14-font-style: normal;
  --rn53themes-net-poppins-medium-14-font-weight: 500;
  --rn53themes-net-poppins-medium-14-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-14-line-height: 21px;
  --rn53themes-net-poppins-medium-14-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-14-upper-font-size: 14px;
  --rn53themes-net-poppins-medium-14-upper-font-style: normal;
  --rn53themes-net-poppins-medium-14-upper-font-weight: 500;
  --rn53themes-net-poppins-medium-14-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-14-upper-line-height: 40px;
  --rn53themes-net-poppins-medium-15-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-15-font-size: 15px;
  --rn53themes-net-poppins-medium-15-font-style: normal;
  --rn53themes-net-poppins-medium-15-font-weight: 500;
  --rn53themes-net-poppins-medium-15-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-15-line-height: 22px;
  --rn53themes-net-poppins-medium-23-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-medium-23-font-size: 23px;
  --rn53themes-net-poppins-medium-23-font-style: normal;
  --rn53themes-net-poppins-medium-23-font-weight: 500;
  --rn53themes-net-poppins-medium-23-letter-spacing: 0px;
  --rn53themes-net-poppins-medium-23-line-height: 27.600000381469727px;
  --rn53themes-net-poppins-regular-13-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-13-font-size: 13px;
  --rn53themes-net-poppins-regular-13-font-style: normal;
  --rn53themes-net-poppins-regular-13-font-weight: 400;
  --rn53themes-net-poppins-regular-13-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-13-line-height: 19.5px;
  --rn53themes-net-poppins-regular-14-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-14-font-size: 14px;
  --rn53themes-net-poppins-regular-14-font-style: normal;
  --rn53themes-net-poppins-regular-14-font-weight: 400;
  --rn53themes-net-poppins-regular-14-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-14-line-height: 21px;
  --rn53themes-net-poppins-regular-14-underline-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-14-underline-font-size: 14px;
  --rn53themes-net-poppins-regular-14-underline-font-style: normal;
  --rn53themes-net-poppins-regular-14-underline-font-weight: 400;
  --rn53themes-net-poppins-regular-14-underline-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-14-underline-line-height: 21px;
  --rn53themes-net-poppins-regular-14-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-14-upper-font-size: 14px;
  --rn53themes-net-poppins-regular-14-upper-font-style: normal;
  --rn53themes-net-poppins-regular-14-upper-font-weight: 400;
  --rn53themes-net-poppins-regular-14-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-14-upper-line-height: 21px;
  --rn53themes-net-poppins-regular-15-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-15-font-size: 15px;
  --rn53themes-net-poppins-regular-15-font-style: normal;
  --rn53themes-net-poppins-regular-15-font-weight: 400;
  --rn53themes-net-poppins-regular-15-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-15-line-height: 26px;
  --rn53themes-net-poppins-regular-16-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-16-font-size: 16px;
  --rn53themes-net-poppins-regular-16-font-style: normal;
  --rn53themes-net-poppins-regular-16-font-weight: 400;
  --rn53themes-net-poppins-regular-16-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-16-line-height: 27px;
  --rn53themes-net-poppins-regular-20-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-regular-20-font-size: 20px;
  --rn53themes-net-poppins-regular-20-font-style: normal;
  --rn53themes-net-poppins-regular-20-font-weight: 400;
  --rn53themes-net-poppins-regular-20-letter-spacing: 0px;
  --rn53themes-net-poppins-regular-20-line-height: 30px;
  --rn53themes-net-poppins-semibold-10-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-semibold-10-upper-font-size: 10px;
  --rn53themes-net-poppins-semibold-10-upper-font-style: normal;
  --rn53themes-net-poppins-semibold-10-upper-font-weight: 600;
  --rn53themes-net-poppins-semibold-10-upper-letter-spacing: 0.5px;
  --rn53themes-net-poppins-semibold-10-upper-line-height: 20px;
  --rn53themes-net-poppins-semibold-13-upper-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-semibold-13-upper-font-size: 13px;
  --rn53themes-net-poppins-semibold-13-upper-font-style: normal;
  --rn53themes-net-poppins-semibold-13-upper-font-weight: 600;
  --rn53themes-net-poppins-semibold-13-upper-letter-spacing: 0px;
  --rn53themes-net-poppins-semibold-13-upper-line-height: 30px;
  --rn53themes-net-poppins-semibold-16-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-semibold-16-font-size: 16px;
  --rn53themes-net-poppins-semibold-16-font-style: normal;
  --rn53themes-net-poppins-semibold-16-font-weight: 600;
  --rn53themes-net-poppins-semibold-16-letter-spacing: 0px;
  --rn53themes-net-poppins-semibold-16-line-height: 25px;
  --rn53themes-net-poppins-semibold-18-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-semibold-18-font-size: 18px;
  --rn53themes-net-poppins-semibold-18-font-style: normal;
  --rn53themes-net-poppins-semibold-18-font-weight: 600;
  --rn53themes-net-poppins-semibold-18-letter-spacing: 0px;
  --rn53themes-net-poppins-semibold-18-line-height: 21.600000381469727px;
  --rn53themes-net-poppins-semibold-20-font-family: "Poppins", Helvetica;
  --rn53themes-net-poppins-semibold-20-font-size: 20px;
  --rn53themes-net-poppins-semibold-20-font-style: normal;
  --rn53themes-net-poppins-semibold-20-font-weight: 600;
  --rn53themes-net-poppins-semibold-20-letter-spacing: 0px;
  --rn53themes-net-poppins-semibold-20-line-height: 32px;
  --rn53themesnetblack-43: rgba(0, 0, 0, 0.43);
  --rn53themesnetblack-58: rgba(0, 0, 0, 0.58);
  --rn53themesnetblack-haze: rgba(250, 251, 251, 1);
  --rn53themesnetblack-russian: rgba(255, 0, 12, 1);
  --rn53themesnetbrick-red: rgba(203, 43, 94, 1);
  --rn53themesnetbright-turquoise: rgba(255, 230, 209, 1);
  --rn53themesnetcyan-aqua: rgba(255, 252, 240, 1);
  --rn53themesnetdark-blue: rgba(11, 41, 203, 1);
  --rn53themesnetebony-clay: rgba(32, 43, 58, 1);
  --rn53themesnethawkes-blue: rgba(205, 222, 252, 1);
  --rn53themesnetirish-coffee: rgba(88, 77, 34, 1);
  --rn53themesnetjapanese-laurel-52: rgba(255, 153, 0, 0.52);
  --rn53themesnetjust-right: rgba(234, 208, 176, 1);
  --rn53themesnetmidnight-blue: rgba(255, 22, 108, 1);
  --rn53themesnetmine-shaft: rgba(51, 51, 51, 1);
  --rn53themesnetnavy-blue: rgba(0, 255, 125, 1);
  --rn53themesnetnero-1: rgba(255, 255, 255, 0.01);
  --rn53themesnetnero-19: rgba(255, 255, 255, 0.19);
  --rn53themesnetnero-55: rgba(255, 255, 255, 0.55);
  --rn53themesnetnero-linear-bianca-cyanaqua: rgba(255, 255, 255, 1);
  --rn53themesnetred-ribbon: rgba(239, 1, 70, 1);
  --rn53themesnetrio-grande: rgba(189, 204, 255, 1);
  --rn53themesnetscarpa-flow: rgba(89, 89, 93, 1);
  --rn53themesnetwest-coast: rgba(102, 69, 28, 1);
  --rn53themesnetwild-sand: rgba(247, 247, 247, 1);
  --rn53themesnetyellow-sea: rgba(246, 175, 4, 1);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-content{
  margin-top: 50px;
}
@media (max-width: 768px) {
  .main-content{
    margin-top: 56px;
  }
}

/* Updated header styles with the improved color wave effect */
.header_navbar__2AyTT {
  position: relative;
  border-radius: 0 0 0 20px;
  background-color: #fff; /* Default background */
  box-shadow: 0 22px 100px 27px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 50px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1100;

}

/* Smoother color wave effect */
.header_navbar__2AyTT::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(234, 47, 111, 0.8) 0%,    /* Start with #ea2f6f at 80% opacity */
    rgba(234, 47, 111, 0.6) 30%,   /* Soften #ea2f6f at 60% opacity */
    rgba(255, 255, 255, 0.5) 60%,  /* Transition to white at 50% opacity */
    rgba(255, 255, 255, 0) 100%    /* End with transparent white */
  );
  opacity: 0; /* Initially invisible */
  animation: header_waveEffect__2oyc2 2.5s ease-out forwards; /* Runs once on load */
}

/* Keyframes for the waving color effect */
@keyframes header_waveEffect__2oyc2 {
  0% {
    left: -100%;
    opacity: 1; /* Starts visible */
  }
  50% {
    left: 0; /* Move to cover the navbar */
  }
  100% {
    left: 100%; /* Moves off the right side */
    opacity: 0; /* Fades out */
  }
}


.header_hamiconNtitlepic__bWpVk {
  display: flex;
  justify-content: center;  
  align-items: center;  
  grid-gap: 10px;  
  gap: 10px;   
}

.header_logo__2KEao {
  width: 151px;
}

.header_navLinks__1zkYs {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  font-size: 14px;
  color: #66451c;
  text-transform: uppercase;
  align-items: center;
}

.header_navItem__2xqm0 {
  text-decoration: none;
  color: #66451C;
  position: relative;
  cursor: pointer;
}

/* Hover effect for nav links */
.header_navItem__2xqm0::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #ea2f6f;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.header_navItem__2xqm0:hover {
  color: #ea2f6f; /* Change color on hover */

}

.header_navItem__2xqm0:hover::before {
  visibility: visible;
  width: 100%;
}



.header_rightSection__1mBJ2 {
  display: flex;
  align-items: center;
  grid-gap: 11px;
  gap: 11px;
  margin-right: 60px;
}

/* Create CV Button */
.header_createCvButton__1UjWE {
  color: var(--Neutrals-Colors-100, #FFF);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 10px;
  background-color: #ea2f6f;
  text-transform: uppercase;
  padding: 7px 11px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 1;
  transform: none;
}

.header_createCvButton__1UjWE:hover {
  box-shadow: 0 5px 15px rgb(89 13 65 / 78%);
  color: #ea2f6f;
  background-color: white;
  border: 1px solid #ea2f6f;
}

/* Premium Section - Desktop */
.header_premiumSection__1OjMP {
  display: flex;
  font-size: 14px;
  color: #66451c;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  padding: 2px 10px;
  border-radius: 10px;
  cursor: pointer;
  opacity: 1;
  position: relative;
  text-decoration: none;
}

.header_premiumSection__1OjMP:hover {
  border: 0.5px solid gold;
}
/* Wave animation for the premium icon */
@keyframes header_wave__2hAgt {
  0% {
    transform: rotate(0deg); /* Starting position */
  }
  25% {
    transform: rotate(15deg); /* Rotate slightly to the right */
  }
  50% {
    transform: rotate(0deg); /* Back to the original position */
  }
  75% {
    transform: rotate(-15deg); /* Rotate slightly to the left */
  }
  100% {
    transform: rotate(0deg); /* Return to original */
  }
}

.header_premiumIcon__24cdX {
  width: 30px;
  height: 30px;
  position: absolute;
  right: -10px;
  top: -11px;
  transition: transform 0.3s ease;
  
  /* Add wave animation */
  animation: header_wave__2hAgt 2s infinite ease-in-out; 
}

/* Support Container */
.header_supportContainer__2MbdM {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 0 10px;
  border-radius: 10px;
  opacity: 1;
  transform: none;
}

.header_supportContainer__2MbdM:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.header_userInfo__3SUy7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header_userRole__3cIEN {
  text-transform: uppercase;
  color: #746a63;
  font-size: 10px;
}

.header_userName___jkWl {
  color: #66451c;
  font-size: 14px;
}

.header_supportIcon__2-iJ8 {
  width: 55px;
}

.header_mobileMenuIcon__2EPMo {
  display: none;
}

/* Mobile Premium Section - Hidden in desktop */
.header_premiumSectionMobile__1Xz0A {
  display: none;
}

/* Mobile styles */
@media (max-width: 1163px) {
  .header_navbar__2AyTT {
    padding: 4px 10px;
    border-radius: 0;
    z-index: 1001;
  }

  .header_logo__2KEao {
    width: 80px;
    margin-bottom: 15px;
  }

  .header_hamiconNtitlepic__bWpVk {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header_supportContainer__2MbdM {
    display: none;
  }

  /* Hide the desktop premium section in mobile view */
  .header_premiumSection__1OjMP {
    display: none;
  }

  /* Show premium section inside the mobile hamburger menu */
  .header_premiumSectionMobile__1Xz0A {
    display: flex;

  }

  .header_premiumIcon__24cdX {
    width: 25px;
    height: 25px;
    position: static;
  }

  .header_navLinks__1zkYs {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 27px;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px 0 0 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }

  .header_navLinks__1zkYs.header_showMenu__CdazU {
    display: flex;
    margin-top: 27px;
  }

  .header_navItem__2xqm0 {
    padding: 10px 0;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid #ddd;
  }

  .header_rightSection__1mBJ2 {
    margin-right: 25px;
  }

  .header_mobileMenuIcon__2EPMo {
    display: block;
  }

  .header_createCvButton__1UjWE {
    font-size: 12px;
  }
}




/* Dropdown container */
.header_dropdownContainer__3CiDV {
  position: relative;
  display: inline-block;
}

/* Dropdown menu for desktop */
.header_dropdownMenu__2iiq4 {
  display: none;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  z-index: 1100;
  min-width: 160px;
}

.header_dropdownMenu__2iiq4 a {
  color: #66451C;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-transform: none;
  font-size: 14px;
}

.header_dropdownMenu__2iiq4 a:hover {
  background-color: #f1f1f1;
}

.header_dropdownContainer__3CiDV:hover .header_dropdownMenu__2iiq4 {
  display: block;
}
.header_dropdownIcon__20DkT {
  aspect-ratio: 3.03;
  object-fit: auto;
  object-position: center;
  width: 18px;
  background-blend-mode: normal;
  padding-left: 5px;
  padding-bottom: 2px;
  margin-left: 5px;
}



/* Dropdown Menu for Mobile */
@media (max-width: 1163px) {
  .header_dropdownContainer__3CiDV {
    width: 100%;
  }
  
  .header_dropdownMenu__2iiq4 {
    display: block;
    position: relative;
    background-color: #ffffff;
    box-shadow: none;
    padding: 0;
    z-index: 1;
    min-width: 100%;
  }
  
  .header_dropdownMenu__2iiq4 a {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
}
.popupContact_popup__1QC-r {
    position: absolute;
    max-width: 400px;
    background: var(--rn-53-themes-net-nero, #FFF);
    box-shadow: 6px 0px 26px -9px rgba(0, 0, 0, 0.28);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px); 
    position: fixed;
    top: 69.9px;
    right: 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: flex-start;
    padding: 36px; 
    z-index: 10001;
    text-align: left;
}

.popupContact_closeButton__3TRZD {
    position: absolute;
    z-index: 0;
    display: flex;
    width: 21.6px; 
    padding-bottom: 2.7px; 
    flex-direction: column;
    justify-content: flex-start;
    right: 18px; 
    top: 18px; 
    background: none;
    border: none;
    cursor: pointer;
}

.popupContact_closeIcon__3RR3Y {
    aspect-ratio: 0.8;
    object-fit: contain;
    object-position: center;
    width: 21.6px; 
}

.popupContact_content__1MxeW {
    z-index: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
}

.popupContact_title__sOx9b {
    width: 100%;
    padding-bottom: 6.3px; 
    color: var(--rn-53-themes-net-west-coast, #66451c);
    font: 400 20px/27px Poppins, -apple-system, Roboto, Helvetica, sans-serif; 
}

.popupContact_contactList__GYmDS {
    display: flex;
    margin-top: 21.6px;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
}

.popupContact_contactItem__2Nj4A {
    display: flex;
    padding-bottom: 0.9px; 
    align-items: center;
    justify-content: flex-start;
    margin-top: 10.8px; 
    text-decoration: none;
}

.popupContact_contactItem__2Nj4A:first-child {
    margin-top: 0;
}

.popupContact_contactLink__a0mlp {
    color: var(--rn-53-themes-net-ebony-clay, #202b3a);
    padding: 0 0 0 22.5px;
    font: 500 14px Poppins, sans-serif; 
    text-align: left;
    text-decoration: none;
}

.popupContact_contactIcon__BCZvo {
    align-items: center;
    border-radius: 5px;
    background: var(--rn-53-themes-net-victoria, #4b3e7e);
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6.1px 8.73px;
}

.popupContact_iconImage__wGW7u {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 16.2px; 
}

.popupContact_expertSupport__3RYDS {
    border-top: 0.6px solid var(--rn-53-themes-net-mercury, #e7e7e7); 
    display: flex;
    margin-top: 19.6px; 
    width: 100%;
    flex-direction: column;
    font-family: Poppins, sans-serif;
    color: var(--rn-53-themes-net-west-coast, #66451c);
    padding: 20px 0 10.8px;
}

.popupContact_expertSupportTitle__3mlS4 {
    padding-bottom: 13.5px; 
    font-size: 14px; 
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2;
}

.popupContact_expertProfile__1Hu4f {
    align-self: flex-start;
    display: flex;
    font-size: 14.4px; 
    font-weight: 400;
    margin: 6.3px 0 0 15.3px; 
}

.popupContact_expertImage__FastK {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 55.5px; 
}

.popupContact_expertInfo__3PNEz {
    display: flex;
    padding-left: 18px; 
    flex-direction: column;
    justify-content: flex-start;
}

.popupContact_expertName__hy1wG {
    width: 100%;
    padding-bottom: 0.9px; 
    font-weight: 700;
    line-height: 1.2;
}

.popupContact_expertTitle__2izNp {
    font-size: 13px; 
    margin-top: 3.6px; 
}

.popupContact_askButton__23-Wg {
    align-self: flex-start;
    border-radius: 4px;
    background: var(--rn-53-themes-net-lynch, #607d8b);
    margin-top: 3.6px; 
    font-family: Josefin Sans, -apple-system, Roboto, Helvetica, sans-serif;
    color: var(--rn-53-themes-net-nero, var(--Neutrals-Colors-100, #fff));
    text-align: center;
    padding: 10.7px 9.8px ; 
    border: none;
    cursor: pointer;
}

.popupContact_socialLinks__210ae {
    display: flex;
    margin-top: 21.6px;
    width: 100%;
    padding-top: 5.4px; 
    align-items: flex-start;
    grid-gap: 3.6px;
    gap: 3.6px; 
    justify-content: flex-start;
}

  .popupContact_socialLink__3B8xO {
    padding: 9px 11px;
    border-radius: 5px;
    background: var(--rn-53-themes-net-wild-sand, #F7F7F7);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
  }
  
  .popupContact_socialLink__3B8xO svg {
    color: black; /* Default black icons */
    width: 18px;
    height: 18px;
    transition: transform 0.4s ease, box-shadow 0.4s ease, color 0.4s ease;
  }
  
  .popupContact_socialLink__3B8xO:hover svg {
    transform: scale(1.2);
    color: #fff; /* Icons will turn white on hover */
  }
  
  .popupContact_socialLink__3B8xO::before {
    content: "";
    position: absolute;
    width: 130%;
    height: 130%;
    left: -110%;
    top: 80%;
    transform: rotate(45deg);
    transition: background 0.7s ease;
  }
  
  .popupContact_socialLink__3B8xO:hover::before {
    animation: popupContact_slide__12Wos 0.7s forwards;
  }
  
  @keyframes popupContact_slide__12Wos {
    50% {
      left: 10%;
      top: -40%;
    }
    100% {
      left: -15%;
      top: -15%;
    }
  }
  
  /* Specific colors for each hover effect */
  .popupContact_socialLink__3B8xO.popupContact_facebook__qm5WB:hover::before {
    background: #3b5998;
  }
  .popupContact_socialLink__3B8xO.popupContact_instagram__1IuQl:hover::before {
    background: linear-gradient(45deg, #f9ce34, #ee2a7b, #6228d7);
  }
  .popupContact_socialLink__3B8xO.popupContact_twitter__EiXpO:hover::before {
    background: #1da1f2;
  }
  .popupContact_socialLink__3B8xO.popupContact_whatsapp__riGTt:hover::before {
    background: #25d366;
  }
  .popupContact_socialLink__3B8xO.popupContact_linkedin__aPBT9:hover::before {
    background: #0077b5;
  }
  .popupContact_socialLink__3B8xO.popupContact_youtube__3qfjs:hover::before {
    background: #ff0000;
  }
  
@media (max-width: 768px) {
    .popupContact_popup__1QC-r {
        zoom: 80%;
        top: 83.1px;
        right: 4.3px;
    }
}

@media (max-width: 375px) {
    .popupContact_popup__1QC-r {
        zoom: 90%;
    }
}

@media (max-width: 320px) {
    .popupContact_popup__1QC-r {
        zoom: 80%;
    }
}


  .footer_container__1IYkB{
    background: var(--preview-themeforest-net-black, #000);
    display: flex;
    flex-direction: column;
  }
  .footer_upperfooter__1Alzs {
    color: #fff;
    font-family: Poppins;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    text-align: left;
  }
  .footer_footersection__3Tcc7 {
    text-align: left;
    width: 30%;
    margin-right: 5px
  }
  .footer_footerTitle__tnCZl {
    color: var(--preview-themeforest-net-white, var(--Neutrals-Colors-100, #FFF));
    font-family: 'Prociono';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.4px; /* 120% */
    margin-bottom: 30px;
  }
  .footer_socialLinks__2JXD9{
    display: flex;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
  }
  .footer_iconLink__3z9xC{
    width: 21px;
    height: 21px;
  }
  .footer_arrow__3_OCz {
    transition: transform 0.3s ease;
    margin-right: 10px;
    display: inline-block; /* Ensure the arrow stays inline with the text */
    vertical-align: middle; /* Align the arrow vertically with the text */
  }
  
  .footer_arrowDown__lqXnm {
    transform: rotate(90deg); /* Rotate the arrow */
  }
  
  .footer_expandedText__aoVNq {
    padding-left: 25px;
    padding-right: 10px;
  }
  .footer_answer__1SvlK{
    cursor: pointer;
  }
  

  .footer_footersection__3Tcc7 p {
    color: var(--preview-themeforest-net-white, var(--Neutrals-Colors-100, #FFF));
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 27.2px; /* 170% */
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    padding-bottom: 10px;
  }
  .footer_footersection__3Tcc7 a {
    color: #fff;
    text-decoration: none;
  }
  .footer_footersection__3Tcc7 a:hover {
    text-decoration: underline;
  }

  .footer_form-section__2yjNK {
    width: 20%;
    position: relative;
    left: -50px;

  }
  .footer_container__1IYkB input[type="text"], .footer_footersection__3Tcc7
  input[type="email"] {
    color: #fff;
    background: var(--preview-themeforest-net-black, #000);
    border: none; 
    border-bottom: 2px solid var(--preview-themeforest-net-white, #FFF);
    outline: none; 
    padding: 8px; 
    width: 279.13px;
    display: flex;
    font-family: Poppins;
  }
  .footer_messageInput__2YDUk {
    width: 93%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    background: var(--preview-themeforest-net-black, #000);
    border-radius: 4px;
    resize: none;
    color: #fff;
    font-family: Poppins;
  }
  
  .footer_sendmsgbutton__gUpNt {
    background: var(--preview-themeforest-net-black, #000);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: 2px solid var(--preview-themeforest-net-white, #FFF);
    cursor: pointer;
    font-family: Poppins;
  }

  .footer_sendmsgbutton__gUpNt:hover {
    background-color:#f5a623 ; /* Darker green on hover */
  }
 .footer_divider__1LaLk {
    border-top: 0.667px solid var(--preview-themeforest-net-dove-gray, #726E6E); 
    margin: 0 auto;
    width: 94%;
  }
  .footer_footerbottom__d3d0Q {
    display: flex;
    padding: 30.07px 0px 14.99px 0px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    color: var(--preview-themeforest-net-white, var(--Neutrals-Colors-100, #FFF));
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px;
  }


@media (max-width: 768px) {
  .footer_container__1IYkB {
    zoom: 80%;
  }
  .footer_upperfooter__1Alzs{
    flex-direction: column;
  }
  .footer_footersection__3Tcc7{
    width: 100%;
    margin-bottom: 20px;
  }
  .footer_footerTitle__tnCZl{
    margin-bottom: 15px
  }
}

.introSec1_container__1Zj1c {
    width: 100%;
    background-color: #f5f2e9;
  }
  
  .introSec1_top_cover__2c9z2 {
    border-radius: 0 0 40px 40px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    max-height: 670px;
  }
  
  .introSec1_intro__10Kcu {
    background-color: #f5f2e9;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  .introSec1_introframe__3CLDh {
    border-radius: 50px 5px;
    background: #ea2f6f;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.70);
    width: 90%;
    height: 438px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -180px;
    padding: 26px 0;
  }
  
  .introSec1_intro_upper__EW3k8 {
    text-align: center;
  }
  
  .introSec1_intro_heading__3Cfhk {
    color: var(--Neutrals-Colors-100, #FFF);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Playfair Display";
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
  }
  
  .introSec1_subheading_intro__GxG_D {
    color: var(--Neutrals-Colors-100, #FFF);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
  }
  
  .introSec1_matrimony_desc__h9wn0 {
    color: var(--Neutrals-Colors-100, #FFF);
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    width: 826px;
    margin: 30px 0;
    word-break: break-word;
  }
  
  .introSec1_intro_footer__36m1d {
    color: var(--Neutrals-Colors-100, #FFF);
    font-family: "Playfair Display";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 150% */
    text-transform: uppercase;
    text-align: right;
    width: 100%;
    margin-right: 128px;
  }
  .introSec1_footer_spn__3qPxB{
    font-size: 42px;
    font-weight: 900;
  }


  /* --------------------- bio data --------------------------------- */

  .introSec1_biodata__1Fxja {
    align-self: end;
    z-index: 10;
    margin-top: -40px;
    width: 100%;
    background-color: white;
  }

  .introSec1_contentWrapper__GwW5f {
    display: flex;
    justify-content: center;
    padding: 0 20px;
  }
  .introSec1_textColumn__2ZVGy{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
  

  .introSec1_textContent__14PaU {
    text-align: left;
  }
  

  
  .introSec1_title__3q1w0 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: 55.2px; 
    text-align: left;

  }
  
  
  .introSec1_ctaButton__31HXI {
    border-radius: 15px;
    background-color: #EA2F6F;
    align-self: start;
    margin-top: 15px;
    color: #FFF;
    text-transform: uppercase;
    padding: 0px 26px 0px 25px;
    font: 500 15px/40px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    outline: none;
    border: none;
    text-decoration: none;
  }
  
  
  .introSec1_imageColumn__2-Tgg {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 55%;

  }
  
  
  .introSec1_bioImage__159_H {
    aspect-ratio: 1.98;
    object-fit: contain;
    object-position: center;
    width: 100%;
    flex-grow: 1;
    position: relative;
    top: -80px;
  }
  
  @media (max-width: 991px) {
    .introSec1_introframe__3CLDh{
      zoom: 40%;
    }
    .introSec1_matrimony_desc__h9wn0{
      font-size: 23px;
    }
    .introSec1_contentWrapper__GwW5f{
      flex-direction: column-reverse;
      margin-bottom: 30px;
    }
    .introSec1_imageColumn__2-Tgg{
      width: auto;
    }
    .introSec1_bioImage__159_H{
      top: 0px;
    }
    .introSec1_title__3q1w0{
      font-size: 21px;
      line-height: 34.2px;
    }
    .introSec1_ctaButton__31HXI{
      font-size: 12px;
      padding: 0px 18px 0px 20px;
    }
}


.whyChooseUsSec2_container__3QtDo {
  display: flex;
  width: 100%;
  height: 285px;
  flex-direction: column;
  align-items: center;
  background: #A8A6CE;

  }
  

  .whyChooseUsSec2_toptitle__16CEj{
    color: var(--Neutrals-Colors-100, #FFF);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Playfair Display";
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    margin: 30px 0 0 0;
    padding: 0;
  }
  .whyChooseUsSec2_cardList__1V6Dr {
    display: flex;
    justify-content: center;
    grid-gap: 5%;
    gap: 5%;
  }
  

  .whyChooseUsSec2_cardWrapper__TDnEe {
    margin-bottom: 20px;
    position: relative;
    bottom: -32px;
  }

  
  .whyChooseUsSec2_card__15Uoo {
    border-radius: 5px;
    background: var(--rn-53-themes-net-nero, #FFF);
    box-shadow: 0px 1px 14px -3px rgba(51, 51, 51, 0.13);
    display: flex;
    width: 350px;
    min-height: 205px;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    padding: 29px 30px;
  }
  

  .whyChooseUsSec2_icon__3KOrW {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 50px;
    align-self: center;
  }
  
  .whyChooseUsSec2_title__19YiB {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    padding: 20px 0 5px;
    text-align: center;
  }
  
  .whyChooseUsSec2_description__2fbEd {
    width: 100%;
    padding-top: 8px;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
  }

  @media (max-width: 991px) {
    .whyChooseUsSec2_container__3QtDo{
      height: auto;
    }
    .whyChooseUsSec2_toptitle__16CEj{
      font-size: 30px
    }
    .whyChooseUsSec2_card__15Uoo{
      max-width: 17rem;
      min-height: 149px;
    }
    .whyChooseUsSec2_cardList__1V6Dr{
      flex-direction: column;
      padding-bottom: 50px;
    }
  }
  
.HowToFindSec3_container__1NVFC {
  background-color: rgba(240, 233, 222, 1);
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  padding-top: 150px;
}

.HowToFindSec3_closeIcon__3BKgy {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
}

.HowToFindSec3_contentWrapper__3OxaV {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HowToFindSec3_headerSection__k1jVa {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HowToFindSec3_titleContainer__3lf78 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.HowToFindSec3_mainTitle__3rGW8 {
  color: var(--rn-53-themes-net-west-coast, #66451c);
  text-align: center;
  font: 600 40px Playfair Display, -apple-system, Roboto, Helvetica, sans-serif;
  margin: 0;
}

.HowToFindSec3_logoContainer__2LvM3 {
  align-self: center;
  display: flex;
}

.HowToFindSec3_logo1__1JJRZ {
  aspect-ratio: 1.69;
  object-fit: contain;
  object-position: center;
  width: 240px;
}

.HowToFindSec3_stepsContainer__2fXUG {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.HowToFindSec3_stepsWrapper__19k5P {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.HowToFindSec3_stepsList__2oUr6 {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.HowToFindSec3_verticalDivider__HP4nk {
  background: var(--rn-53-themes-net-bone, #ddcebc);
  position: absolute;
  z-index: 0;
  display: flex;
  min-height: 1370px;
  width: 1px;
  right: 50%;
  top: 0px;
  bottom: 0px;
}

.HowToFindSec3_stepItem__2wWjx {
  z-index: 0;
  display: flex;
  width: 100%;
  padding-bottom: 50px;
  flex-direction: column;
  justify-content: flex-start;
}

.HowToFindSec3_stepContent__3Vabb {
  display: flex;
  width: 100%;
  justify-content: center;
  grid-gap: 10%;
  gap: 10%;
}

.HowToFindSec3_stepImageContainer__20mUj {
  display: flex;
  width: 34%;
  align-items: flex-start;
  justify-content: flex-end;
}

.HowToFindSec3_stepImage__22kBy {
  aspect-ratio: 1;
  object-position: center;
  width: 128px;
  max-width: 450px;
}

.HowToFindSec3_stepTextContainer__2jXlf {
  display: flex;
  width: 34%;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  color: var(--rn-53-themes-net-west-coast, #66451c);
  justify-content: flex-start;
  text-align: left;
}

.HowToFindSec3_stepTitle__2wqJX {
  color: #66451C;
  font-family: "Playfair Display";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
}

.HowToFindSec3_stepSubtitle__3JWsi {
  color: var(--rn-53-themes-net-razzmatazz, #eb0359);
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 11px;
}

.HowToFindSec3_stepDescription__3bfxP {
  margin-top: 11px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  padding: 10px 0 15px;
}

.HowToFindSec3_stepItemReverse__3FHmm .HowToFindSec3_stepTextContainer__2jXlf {
  text-align: right;
}

.HowToFindSec3_stepItemReverse__3FHmm .HowToFindSec3_stepSubtitle__3JWsi {
  align-self: flex-end;
}

.HowToFindSec3_stepItemReverse__3FHmm .HowToFindSec3_stepImageContainer__20mUj {
  justify-content: flex-start;
}

.HowToFindSec3_backgroundBorderShadow__21ksp {
  border-radius: 50px;
  border: 4px solid var(--rn-53-themes-net-nero, #fff);
  background: var(--rn-53-themes-net-west-coast, #66451c);
  box-shadow: 0px 0px 10px 0.6px rgba(40, 30, 20, 0.08);
  position: absolute;
  z-index: 0;
  display: flex;
  width: 17px;
  height: 17px;
    /* Centering the element horizontally */
    left: 50%;
    transform: translateX(-50%);
}

/* Responsive Styles for Mobile */
@media (max-width: 991px) {
  .HowToFindSec3_container__1NVFC{
    padding-top: 50px;
  }
  .HowToFindSec3_mainTitle__3rGW8{
    font-size: 20px;
  }
  .HowToFindSec3_logo1__1JJRZ{width: 160px;}
  .HowToFindSec3_stepImageContainer__20mUj {
      width: 42%;
    }
  .HowToFindSec3_stepTextContainer__2jXlf{
    width: 42%;
  }
  .HowToFindSec3_stepTitle__2wqJX{
    font-size: 18px;
    line-height: normal;
  }
  .HowToFindSec3_stepSubtitle__3JWsi{
    font-size: 8px;
    line-height: normal;
  }
  .HowToFindSec3_stepDescription__3bfxP{
    font-size: 10px;
    line-height: normal;
  }
}
.testimonialSec4_testimonialSection__3y-zd {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  color: var(--rn-53-themes-net-west-coast, #66451c);
  font-weight: 400;
  position: relative;
}
.testimonialSec4_grass__2uZpr {
  width: 100%;            /* Makes the image take up 100% of the width of the parent div */
  height: 100%;           /* Makes the image take up 100% of the height of the parent div */
  object-fit: cover;      /* Ensures the image covers the entire area, maintaining aspect ratio and cropping if necessary */
  object-position: center; /* Centers the image within the div */
}

.testimonialSec4_shadowImageContainer__3_6fL {
  position: relative;
  object-fit: contain;
  object-position: center;
}

.testimonialSec4_shadowImage__2SDVt {
  width: 100%;
}

.testimonialSec4_toptitle__1IjIE {
  color: var(--rn-53-themes-net-west-coast, #66451C);
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 5px;
  font-family: "Playfair Display";
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 55.2px; /* 120% */
}

.testimonialSec4_testimonialList__3fvC7 {
  display: flex;
  justify-content: space-around;
  padding: 97px 8px 0px 10px;
  align-items: center;
  scroll-behavior: smooth;
  width: 100%;
}
.testimonialSec4_cardsss__1sAuS{
  width: 80%;
}
.testimonialSec4_arrowContainer__1DKov {
  width: 50px;
  height: 50px; /* Make sure the arrow container is square */
  border-radius: 50%; /* Change to make the container circular */
  border: 1px solid rgba(255, 255, 255, 0.55);
  background: rgba(255, 153, 0, 0.52);
  cursor: pointer;
  position: absolute;
  top: 60%; /* Center the arrow vertically */
  transform: translateY(-60%); /* Adjust the vertical positioning */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for better visibility */
  z-index: 10; /* Ensure the arrow is on top of other elements */
}

.testimonialSec4_leftArrow___nyx6 {
  left: 10px; /* Adjust as needed */
}

.testimonialSec4_rightArrow__2nttI {
  right: 10px; /* Adjust as needed */
}
.testimonialSec4_cardlistcontainer__pGd7W{
  max-width: 1272px;
  overflow: hidden;
  margin-top: 30px;
  align-items: stretch; 
  display: flex;
  justify-content: space-between;
}
.testimonialSec4_cardContent__myRpJ {
  border-radius: 10px;
  position: relative;
  display: flex;
  width: 405px;
  flex-direction: column;
  justify-content: space-between;
  padding: 91px 36px 31px;
  border: 1px solid rgba(143, 44, 90, 1);
  text-align: left;
  flex-shrink: 0; /* Prevent the card from shrinking */
  margin-right: 16px; /* Add spacing between the cards */
  flex-grow: 1;  /* Allow the card to grow and fill the available space */
  height: 100%; 
  box-sizing: border-box;
}

.testimonialSec4_testimonialText__2fEgd {
  width: 100%;
  font-size: 15px;
  line-height: 23px;
  text-align: left;
  white-space: normal; /* Allow text to wrap to the next line */
  word-wrap: break-word; /* Break long words if needed */
  overflow-wrap: break-word;
}

.testimonialSec4_authorName__uRnKY {
  margin-top: 16px;
  width: 100%;
  padding-top: 15px;
  font: 700 18px/1 'Cinzel Decorative', -apple-system, Roboto, Helvetica, sans-serif;
  text-align: left;
}

.testimonialSec4_authorInfo__3Q_lY {
  font-size: 16px;
  z-index: 0;
  margin-top: 16px;
}

.testimonialSec4_box1__2WJLn, .testimonialSec4_box2__2eF9Q, .testimonialSec4_box3__39OFf {
  width: 10px;
  height: 10px;
  position: absolute;
  z-index: 10;
}

.testimonialSec4_box1__2WJLn {
  left: 17px;
  top: 34px;
  border: 2px solid var(--rn-53-themes-net-green, #FFC600);
}

.testimonialSec4_box2__2eF9Q {
  left: 31px;
  top: -67px;
  border: 2px solid var(--rn-53-themes-net-geraldine, #FD8686);
}

.testimonialSec4_box3__39OFf {
  left: 142px;
  top: -55px;
  border: 2px solid var(--rn-53-themes-net-rio-grande, #BDCCFF);
}

.testimonialSec4_authorImage__2ifNQ {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 130px;
  position: absolute;
  z-index: 0;
  max-width: 100%;
  left: 36px;
  top: -64px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .testimonialSec4_testimonialCard__1xtv0 {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }
.testimonialSec4_cardlistcontainer__pGd7W{
  max-width: 311px;
  justify-content: center;
}
  .testimonialSec4_cardContent__myRpJ {
    padding: 91px 20px 31px;
    width: 310px;
  }
  .testimonialSec4_arrowContainer__1DKov {
    width: 40px;
    height: 40px;
  }
}


.meetFounder_meetFounderSection__2H28R {
  background-color: #fff;
  overflow: hidden;
}

.meetFounder_contentWrapper__1KztX {
  display: flex;
  grid-gap: 50px;
  gap: 50px;
  justify-content: space-between;
}

.meetFounder_textColumn__113ib {
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin-left: 40px;
}

.meetFounder_titleWrapper__2rwaw {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: #bf5a5a;
  font: 700 30px Playfair Display, -apple-system, Roboto, Helvetica, sans-serif;
  margin: 110px 30px 0 35px;
}

.meetFounder_title__37xbv {
  align-self: flex-start;
  margin-left: 10px;
}

.meetFounder_decorativeBorder__2HVag {
  border-radius: 20px;
  display: flex;
  margin: 5px 0 0 -1px;
  border: 2px solid #8f2c5a;
}
.meetFounder_founderText__1y47S{
  color: #BC6868;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  padding: 13px;
}
.meetFounder_imageColumn__3wC_O {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  line-height: normal;
  margin-left: 20px;
  position: relative;
  align-items: center;
}
.meetFounder_founderContainer__3V2yv{
position: relative;
}
.meetFounder_founderImage__2i4B7 {
  aspect-ratio: 0.83;
  object-fit: contain;
  object-position: center;
  transition: transform 0.3s ease-in-out;
  position: relative;
  width: 558px;
}

.meetFounder_founderTopImage__1w2t1{
  position: absolute;
  top: 58px;
  left: 50%;
  transform: translateX(-50%); 
  z-index: 1;
}
.meetFounder_namecardTextContainer__mSqg-{
  text-align: left;
  color: var(--Neutrals-Colors-100, #FFF);
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 17px 73px;
}
.meetFounder_founderName__1o8dC{
  font-family: "Playfair Display";
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.meetFounder_namecardImage__3_ipS {
  background-image: url('/assets/namecard.svg');
  background-size: contain; 
  background-repeat: no-repeat; 
  background-position: center; 
  position: absolute;
  top: 449px;
  right: 239px;
  width: 100%;
  height: 148px; 
  z-index: 0;
  text-align: left;
}

.meetFounder_namecardbottomImage__3jyZ9{
    position: absolute;
    top: 569px;
    left: -238px;
  }
  .meetFounder_socialLinks__283rN {
    position: absolute;
    border-radius: 15px;
    background: rgba(217, 217, 217, 0.70);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 299px;
    bottom: 77px;
    z-index: 10;
    left: 148px;
    grid-gap: 5px;
    gap: 5px; 
  }
  
  .meetFounder_iconLink__HXQh1 {
    padding: 9px 11px;
    border-radius: 14px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
  
  .meetFounder_iconLink__HXQh1 svg {
    color: black; /* Default black icons */
    width: 18px;
    height: 18px;
    transition: transform 0.4s ease, box-shadow 0.4s ease, color 0.4s ease;
  }
  
  .meetFounder_iconLink__HXQh1:hover svg {
    transform: scale(1.2);
    color: #fff; /* Icons will turn white on hover */
  }
  
  .meetFounder_iconLink__HXQh1::before {
    content: "";
    position: absolute;
    width: 130%;
    height: 130%;
    left: -110%;
    top: 80%;
    transform: rotate(45deg);
  }
  
  .meetFounder_iconLink__HXQh1:hover::before {
    animation: meetFounder_slide__2clgF 0.7s forwards;
  }
  
  @keyframes meetFounder_slide__2clgF {
    50% {
      left: 10%;
      top: -40%;
    }
    100% {
      left: -15%;
      top: -15%;
    }
  }
  
  /* Specific colors for each hover effect */
  .meetFounder_iconLink__HXQh1.meetFounder_facebook__3U-qW:hover::before {
    background: #3b5998;
  }
  .meetFounder_iconLink__HXQh1.meetFounder_instagram__2aBsy:hover::before {
    background: linear-gradient(45deg, #f9ce34, #ee2a7b, #6228d7);
  }
  .meetFounder_iconLink__HXQh1.meetFounder_twitter__3S7rV:hover::before {
    background: #1da1f2;
  }
  .meetFounder_iconLink__HXQh1.meetFounder_whatsapp__3ewiz:hover::before {
    background: #25d366;
  }
  .meetFounder_iconLink__HXQh1.meetFounder_linkedin__3O-WK:hover::before {
    background: #0077b5;
  }
  .meetFounder_iconLink__HXQh1.meetFounder_youtube__1BzX6:hover::before {
    background: #ff0000;
  }
  


@media (max-width: 768px) {
  .meetFounder_titleWrapper__2rwaw{
    font-size: 20px;
  }
  .meetFounder_founderImage__2i4B7{
      width: 383px;
  }
  .meetFounder_founderText__1y47S{
    font-size: 14px;
  }
  .meetFounder_founderTopImage__1w2t1{
    top: 0;
    width: 289px;
  }

  .meetFounder_namecardImage__3_ipS{
    top: 360px;
    right: 0px;
  }
  .meetFounder_founderName__1o8dC{
    font-size: 18px;
  }
  .meetFounder_namecardTextContainer__mSqg-{
    padding: 34px 73px;
    font-size: 12px;
  }
  .meetFounder_namecardbottomImage__3jyZ9{
    top: 464px;
    left: 0px;
  }
  .meetFounder_imageColumn__3wC_O{
    margin-bottom: 100px;
    margin-left: 0px;
  }
  .meetFounder_socialLinks__283rN{
    bottom: 85px;
    left: 60px;
  }
  .meetFounder_contentWrapper__1KztX {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
 .meetFounder_textColumn__113ib{
  margin-left: 0px;
 }

}


.storiesNblogsSec6_blogscardContainer__2LvxX {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 50px;
  transition: transform 0.5s ease-in-out; /* Smooth slide animation */
  transform: translateX(calc(-100% * var(--currentIndex))); /* Slide to show the current cards */
}

.storiesNblogsSec6_sliderContainer__38FUy {
  position: relative; 
  width: 100%;
  overflow: hidden; 
}

.storiesNblogsSec6_arrowIcon__dRufc {
  font-size: 30px;
  cursor: pointer;
  color: #8f2c5a;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  padding: 10px;
  border-radius: 10px;
  background: rgba(196, 74, 74, 0.075); /* Semi-transparent white */
  backdrop-filter: blur(10px); /* Blurs everything behind */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
}

.storiesNblogsSec6_arrowIcon__dRufc:first-of-type {
  left: 35px;
}

.storiesNblogsSec6_arrowIcon__dRufc:last-of-type {
  right: 35px;
}


.storiesNblogsSec6_arrowIcon__dRufc:first-of-type {
  left: 35px;
}

.storiesNblogsSec6_arrowIcon__dRufc:last-of-type {
  right: 35px; 
}

.storiesNblogsSec6_sectionTitle__uSHuI {
  display: flex;
  justify-content: center;
  color: var(--rn-53-themes-net-west-coast, #66451C);
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Playfair Display";
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 55.2px; /* 120% */
  margin-bottom: 40px;
}

.storiesNblogsSec6_card__31hs0 {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute space evenly */
  flex-grow: 1; /* Ensure all cards grow equally */
  width: 100%;
  max-width: 380px; /* Max width of each card */
  height: 534px;
  background-color: #fff;
  box-shadow: 9.899px 9.899px 30px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

.storiesNblogsSec6_cardContent__blkiv {
  flex-grow: 1; /* Ensures all card content takes up available space */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;

}

.storiesNblogsSec6_imageWrapper__YUNi0 {
  position: relative;
  width: 100%;
  height: 200px; /* Fixed height for the image */
  overflow: hidden; /* Ensures no overflow for images */
}

.storiesNblogsSec6_image__331TV {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures all images cover the wrapper equally */
}

.storiesNblogsSec6_category__1M8YZ {
  position: absolute;
  left: 30px;
  top: 15px;
  z-index: 1;
  color: #fff;
  font: 500 13px/1.2 Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.storiesNblogsSec6_categoryTag__1kaep {
  border-radius: 5px;
  background-color: #a97453;
  padding: 3px 8px;
}

.storiesNblogsSec6_textContent__3EHUJ {
  padding: 10px 30px 30px;
  color: #000;
  font: 300 16px/27px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: left;
  white-space: normal; /* Allow text to wrap to the next line */
  word-wrap: break-word; /* Break long words if needed */
  overflow-wrap: break-word;
  flex-grow: 1; /* Ensure the text content stretches to fill available space */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.storiesNblogsSec6_pinType__15xvr {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.storiesNblogsSec6_pintext__GlgCA {
  padding-left: 10px;
}

.storiesNblogsSec6_title__fBMMg {
  font: 400 22px/1.2 Prociono, -apple-system, Roboto, Helvetica, sans-serif;
  margin-bottom: 7px;
}

.storiesNblogsSec6_excerpt__3QmT0 {
  margin-bottom: 20px;
  overflow: hidden; /* Prevents text from overflowing */
  text-overflow: ellipsis; /* Adds ellipsis for long text */
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.storiesNblogsSec6_readMore__2UlV7 {
  display: inline-block;
  border-radius: 5px;
  padding: 10px 30px;
  border: 2px solid #8f2c5a;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  color: inherit;
}

.storiesNblogsSec6_readMore__2UlV7:hover {
  background-color: #8f2c5a;
  color: #fff;
}

@media (max-width: 768px) {
  .storiesNblogsSec6_arrowIcon__dRufc:first-of-type {
    left: 0px;
  }
  
  .storiesNblogsSec6_arrowIcon__dRufc:last-of-type {
    right: 0px; 
  }
  .storiesNblogsSec6_sectionTitle__uSHuI {
    font-size: 30px;
  }

  .storiesNblogsSec6_card__31hs0 {
    zoom: 85%;
  }

  .storiesNblogsSec6_blogscardContainer__2LvxX {
    flex-direction: column;
    align-items: center;
  }
  .storiesNblogsSec6_arrowIcon__dRufc {
    font-size: 20px; /* Adjust arrow size on mobile */
  }
}


.contact_container__1JW3U {
  display: flex;
  flex-direction: column;
}

.contact_getintouch__kCi0d {
  display: flex;
  justify-content: center;
  text-align: left;
  z-index: 10;
}

.contact_leftSection__2Vv09 {
  background: var(--preview-themeforest-net-old-lace, #FDF3E2);
  text-align: left;
  padding: 50px;
  align-self: stretch;
  width: 569.76px;
}

.contact_leftSection__2Vv09 h2 {
  color: var(--preview-themeforest-net-black, #000);
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 31.2px; /* 120% */
}

.contact_leftSection__2Vv09 p {
  color: var(--preview-themeforest-net-black, #000);
  font-family: 'Poppins', 'sans-serif';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 27.2px; /* 170% */
  padding-top: 16px;
}

.contact_signature__28vBk {
  margin-top: 30px;
  color: var(--preview-themeforest-net-black, #000);
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 19.2px; /* 120% */
}

.contact_logo__3w5a6 {
  margin-top: 10px;
  width: 151.2px;
  height: 60px;
}

.contact_rightSection__3jCGK {
  width: 340.24px;
  background: var(--preview-themeforest-net-deep-bronze, #513606);
  color: var(--preview-themeforest-net-white, var(--Neutrals-Colors-100, #FFF));
  display: flex;
  padding: 71.64px 40px 71.65px 40px;
  align-items: center;
  align-self: stretch;
}

.contact_contactInfo__N8FyV {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.contact_iconText__18DHO {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.contact_iconText__18DHO img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.contact_iconText__18DHO span {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
}

.contact_mapIframe__2cxkn {
  width: 100%;
  height: 400px;
  border: none;
  margin-top: -150px; /* Adjust as needed */
}

@media (max-width: 768px) {
  .contact_getintouch__kCi0d {
    flex-direction: column;
  }
  .contact_leftSection__2Vv09, .contact_rightSection__3jCGK {
    width: auto;
  }
  .contact_mapIframe__2cxkn {
    margin-top: 0;
  }
}

.filepicker_thumbnail__1cVaj{
    width: 150px;
    height: 150px;
    object-fit: cover;
}
.filepicker_tips__3mXK-{
    height: 150px;
    width: auto;
}
@media (max-width: 768px) {
    .filepicker_thumbnail__1cVaj{
        width: 120px;
        height: 120px;
    }
    .filepicker_tips__3mXK-{
        height: 83px;
        width: auto;
    }
}

/* profileInformation.module.css */

.profileInformation_formContainer__3BIva {
  width: 80%;
  margin: 5rem auto;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  font-family: Nunito;
  font-weight: 600;
}

.profileInformation_title__6U2wH{
  padding: 10px 0;
  color: #484848;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.profileInformation_formGroupContainer__1jC_f{
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.profileInformation_formGroup__2FDl6 {
  margin-bottom: 1.5rem;
  width: 100%;
}

.profileInformation_label__KfLqN {
  display: block;
  margin-bottom: 0.5rem;
  color: #484848;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.profileInformation_input__3ZM6M,
.profileInformation_textarea__1_QHF {
  width: 100%;
  padding: 0.638rem;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box; 
}

.profileInformation_inputError__1-Fbz {
  border-color: red;
}

.profileInformation_radioGroup__3F9gY {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}

.profileInformation_radioInput__JJUT5 {
  display: none;
}
.profileInformation_radioContainer__3fKlJ{
  padding: 0.5rem 0;
}
.profileInformation_radioLabel__36ehN {
  padding: 0.5rem 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
}

.profileInformation_radioInput__JJUT5:checked + .profileInformation_radioLabel__36ehN {
  background-color: #504d4c;
  color: white;
  border-color: #504d4c;
}

.profileInformation_dobGroup__Qh9bI {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.profileInformation_submitButton__1NQyx {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #070e16;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.profileInformation_submitButton__1NQyx:hover {
  background-color: #1b1b28;
}

.profileInformation_errorMessage__19ozD {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
@media (max-width: 768px)  {
  .profileInformation_formGroupContainer__1jC_f{
    flex-direction: column;
  }
}
.AddressInfo_container__7pBse {
    width: 80%;
    margin: 5rem auto;
    font-family: Nunito;
  }
  
  .AddressInfo_inputGroup__3f8xb, .AddressInfo_checkboxGroup__Luj45 {
    margin-bottom: 1.5rem;
  }
  
  .AddressInfo_row__2louD {
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  
  .AddressInfo_column__3453h {
    flex: 1 1;
    margin-bottom: 1.5rem;
  }
  .AddressInfo_title__3bJ3w{
    padding: 10px 0;
    color: #484848;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .AddressInfo_label__2D2Hv {
    display: block;
    margin-bottom: 0.5rem;
    color: #484848;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .AddressInfo_inputField__2uHms{
    width: 100%;
    padding: 0.638rem;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box; 
  }
  
  .AddressInfo_inputField__2uHms:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .AddressInfo_checkboxGroup__Luj45 {
    display: flex;
    align-items: center;
  }
  
  .AddressInfo_checkbox__UkjGI {
    margin-right: 0.5rem;
  }
  
  .AddressInfo_checkboxLabel__1EXBb {
    font-size: 1rem;
  }
  
  .AddressInfo_buttonGroup__34-wa {
    display: flex;
    justify-content: space-between;
  }
  
  .AddressInfo_button__3vsWb {
    background-color: #070e16;
    color: white;
    border: none;
    padding: 0.75rem 4rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .AddressInfo_button__3vsWb:hover {
    background-color: #1b1b28;
  }
  
.education_container__1M45h {
    width: 80%;
    margin: 5rem auto;
    font-family: Nunito;
  }
  .education_title__s0rAM{
    padding: 10px 0;
    color: #484848;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .education_row__1CMJM {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .education_col__1OvM8 {
    flex: 1 1;

  }
  
  .education_inlineFields__35WQF {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .education_smallField__jUcOM {
    flex: 1 1;
  }
  
  .education_largeField__MBpTe {
    flex: 2 1;
  }
  
  .education_formLabel__J_DCB {
    margin-bottom: 0.5rem;
    display: block;
    color: #484848;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .education_formControl__3esXS {
    width: 100%;
    padding: 0.638rem;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box; 
  }
  
  .education_btnGroup__f4YPi {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .education_btnCheck__3Oh2m {
    display: none;
    
  }
  
  .education_btnLabel__mwv4H {
    padding: 0.5rem 1rem;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    border-radius: 5px;
    border: 1px solid #E5E5E5;
    background: #FFF;
    color: #454545;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  

  .education_btnCheck__3Oh2m:checked + .education_btnLabel__mwv4H {
    background-color: #607D8B;
  color: white;
  }
  
  .education_submitButton__22KFL,
  .education_backButton__3_e2P {
    padding: 0.75rem 4rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #070e16;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 1rem;
  }
  .education_buttonGroup__1L1FP {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .education_submitButton__22KFL:hover,
  .education_backButton__3_e2P:hover {
    background-color: #1b1b28;
  }
  @media (max-width: 768px) {
   .education_row__1CMJM{
    flex-direction: column;
   }
   .education_submitButton__22KFL,
   .education_backButton__3_e2P {
     padding: 0.75rem 2rem;
   }
  }

/* FamilyInformationForm.module.css */

.FamilyInformationForm_container__6rTbM {
    width: 80%;
    margin: 5rem auto;
    font-family: Nunito;
  }
  .FamilyInformationForm_title__29eXc{
    padding: 10px 0;
    color: #484848;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .FamilyInformationForm_fieldRow__3KfYP {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 20px;
  }

  .FamilyInformationForm_fieldColumn__15SRN {
    flex: 1 1;
    min-width: 250px;
  }
  
  .FamilyInformationForm_fieldLabel__3_rRo {
    display: block;
    margin-bottom: 0.5rem;
    color: #484848;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .FamilyInformationForm_textField__2xpLO  {
    width: 100%;
    padding: 0.638rem;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box; 
  }
  
  .FamilyInformationForm_textField__2xpLO:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .FamilyInformationForm_buttonGroup__3nXkf {
    display: flex;
    justify-content: space-between;
  }
  
  .FamilyInformationForm_button__3-aLl {
    padding: 0.75rem 4rem;
    background-color: #070e16;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .FamilyInformationForm_button__3-aLl:hover {
    background-color: #1b1b28;
  }
  @media (max-width: 768px){
    .FamilyInformationForm_button__3-aLl {
      padding: 0.75rem 2rem;
    }

  }
/* PartnerPreferences.module.css */

.PartnerPreferences_container__1o5jM {
    width: 80%;
    margin: 5rem auto;
    font-family: Nunito;
  }
  
  .PartnerPreferences_title__1r3ep{
    padding: 10px 0;
    color: #484848;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .PartnerPreferences_row__eRRgJ {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .PartnerPreferences_column__1EUUh {
    flex: 1 1;
    min-width: 250px;
  }
  
  .PartnerPreferences_label__1sPKU {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
  }
  
 .PartnerPreferences_input__Ti-jP {
    width: 100%;
    padding: 0.638rem;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box; 
  }
  
  .PartnerPreferences_inputGroup__26gKV {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .PartnerPreferences_districtGroup__2Yby2 {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .PartnerPreferences_imageUploadContainer__1DqOo {
    background: #D9D9D9;
    border-radius: 5px;
    min-height: 150px;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    flex-wrap: wrap;
  }
  
  .PartnerPreferences_imageInputContainer__j92F3 {
    display: flex;
    align-items: center;
  }
  
  .PartnerPreferences_fileInput__1JQ8e {
    display: none;
  }
  
  .PartnerPreferences_imageInputLabel__3USER {
    border-radius: 15px;
    border: 1px solid #000;
    background: #FF6584;
    color: var(--Neutrals-Colors-100, #FFF);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0.5625rem 3rem;
  }
  
  .PartnerPreferences_imagePreviewContainer__hg-S4 {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
    align-items: center;
  }
  
  .PartnerPreferences_imageWrapper__EwinO {
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }
  
  .PartnerPreferences_imageThumbnail__7IY5j {
    width: 100px;
    height: 98px;
    object-fit: cover;
  }
  
  .PartnerPreferences_closeButton__1pj2M {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #e9f2f6;
    cursor: pointer;
    font-size: 1.5rem; 
  }
  .PartnerPreferences_buttonGroup__27eia {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .PartnerPreferences_submitButton__3_jrE,
  .PartnerPreferences_backButton__2wtL6 {
    padding: 0.75rem 4rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #070e16;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 1rem;
  }
  
  .PartnerPreferences_submitButton__3_jrE:hover,
  .PartnerPreferences_backButton__2wtL6:hover {
    background-color: #1b1b28;
  }
  @media (max-width: 768px){
    .PartnerPreferences_submitButton__3_jrE,
    .PartnerPreferences_backButton__2wtL6 {
      padding: 0.75rem 2rem;
    }

  }
.CVpagecontainer{
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    form{
        text-align: left;
    }
    
    .form-check.me-3:first-child {
        padding-left: 0;
    }
    .CVdescription {
        width: 100%;
        max-width: 874px;
        color: rgba(0, 0, 0, 1);
        text-align: center;
        padding: -5px 0 0;
        font: 400 16px Nunito, sans-serif;
      }
      
      .boldText {
        margin-top: 1rem;
        font-weight: 700;
      }
      
      @media (max-width: 991px) {
        .CVpagecontainer{
          margin: 80px 20px 0 20px;
        }
        .CVdescription {
          max-width: 100%;
        }
        .pagetitle{
          font-size: 20px;
        }
      }
      
      @media (max-width: 768px) {
        .CVpagecontainer{
          margin: 80px 20px 0 20px;
        }
        .CVdescription {
          font-size: 16px;
        }
      }
      
      @media (max-width: 480px) {
        .CVpagecontainer{
          margin: 80px 20px 0 20px;
        }
        .CVdescription {
          font-size: 14px;
        }
      }
.page1_container__2f5C9 {
    display: flex;
  width: 595px;
  height: 842px;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  }
  
  
  .page1_profileSection__1ryN8 {
    display: flex;
    justify-content: space-between;
  }
  
  .page1_imageColumn__1x0Hz {
    display: flex;
    flex-direction: column;
    line-height: normal;
  }
  
  .page1_profileImage__1znUR {
    aspect-ratio: 1.28;
    object-fit: contain;
    object-position: center;
    width: 100%;
    flex-grow: 1;
  }
  
  
  .page1_logo__1VkDs {
    aspect-ratio: 2.5;
    object-fit: contain;
    object-position: center;
    width: 100px;
    max-width: 100%;
    padding: 1rem;
  }
  
  .page1_bioSection__1Nm2n {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  
  .page1_bioTitle__Kc9py {
    color: #000;
    font: 500 25px 'Playfair Display', -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .page1_name__1PfYn {
    color: #0a1f67;
    text-align: center;
    margin-top: 70px;
    font: 500 25px 'Playfair Display', -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .page1_details__2irPN {
    margin-top: 20px;
    color: #0B1165;
    text-align: center;
    font-family: Playball, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .page1_decorativeElement__2FDaO {
    aspect-ratio: 0.67;
    object-fit: contain;
    object-position: center;
    width: 2px;
    align-self: flex-end;
    margin-top: 28px;
  }
  
  .page1_decorativeWrapper__1UGlB {
    align-self: flex-end;
    display: flex;
    grid-gap: 2px;
    gap: 2px;
  }
  
  .page1_decorativeImage__19Ick {
    aspect-ratio: 1.2;
    object-fit: contain;
    object-position: center;
    width: 6px;
    margin: auto 0;
  }
  
  .page1_decorativeColumn__3yy1Q {
    display: flex;
    justify-content: flex-end;
  }
  
  .page1_decorativeBar__1rd4o {
    aspect-ratio: 0.5;
    object-fit: contain;
    object-position: center;
  }
  
  .page1_decorativeEnd__1_ZCD {
    aspect-ratio: 0.5;
    object-fit: contain;
    object-position: center;
    width: 3px;
    align-self: flex-end;
  }
  
  @media (max-width: 768px) {
  .page1_container__2f5C9{
    transform: scale(0.7); /* scales content without affecting PDF output */
    transform-origin: top center;
  }
  }
  
.bioData_header_header__3M8pb {
    display: flex;
    flex-direction: column;
    font-family: Prata, sans-serif;
    font-weight: 400;
    position: absolute;
    top: 0px;
    width: 100%;
  }

  
  .bioData_header_headerContent__2e-aW {
    justify-content: space-between;
    display: flex;
  }
  
  .bioData_header_nameSection__18pKH {
    display: flex;
    font-size: 16px;
    color: #0b1165;
  }
  
  .bioData_header_logo__nYvZT {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 100px;
    max-width: 100%;
  }
  
  .bioData_header_name__2-gKo {
    align-self: flex-start;
    margin-top: 78px;
    flex-basis: auto;
  }
  
  @media (max-width: 991px) {
    .bioData_header_name__2-gKo {
      margin-top: 40px;
    }
  }
  
  .bioData_header_bioDataSection__VNTg6 {
    align-self: flex-start;
    display: flex;
    margin-top: 4px;
    flex-direction: column;
    font-size: 12px;
    color: #000;
  }
  
  .bioData_header_bioDataLogo__1dFzw {
    aspect-ratio: 6.67;
    object-fit: contain;
    object-position: center;
    width: 100px;
  }
  
  .bioData_header_bioDataText__3UZEr {
    align-self: center;
    margin-top: 20px;
  }


.bioData_footer_footer__1A7Yw {
    width: 100%;
    position: absolute;
    bottom: 0px;
  }
  
  .bioData_footer_footerContent__3SavL {
    display: flex;
    justify-content: space-between;
  }
  
  .bioData_footer_footerImage__1ahha {
    display: flex;
    flex-direction: column;
    line-height: normal;
  }
  
  .bioData_footer_footerLogo__1wTEx {
    aspect-ratio: 1.01;
    object-fit: contain;
    object-position: center;
    width: 100px;
    max-width: 100%;
    flex-grow: 1;
  }
  
  .bioData_footer_footerText__22e6o {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 53%;
  }
  
  .bioData_footer_poweredBy__2-KDn {
    display: flex;
    margin-top: 57px;
    flex-direction: column;
    color: #000;
    text-align: center;
    text-transform: uppercase;
    font: 300 6px/17px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .bioData_footer_butterflyLogo__2iYXc {
    aspect-ratio: 2.5;
    object-fit: contain;
    object-position: center;
    width: 40px;
    align-self: center;
  }
  @media (max-width: 991px) {
    .bioData_footer_footer__1A7Yw {
        max-width: 100%;
      }
    
      .bioData_footer_footerLogo__1wTEx {
        margin-top: 40px;
      }
    
      .bioData_footer_footerText__22e6o {
        width: 100%;
      }
    
      .bioData_footer_poweredBy__2-KDn {
        margin-top: 40px;
      }
    }

.page2_card__1uXon {
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  width: 595px;
  height: 842px;
    overflow: hidden;
    position: relative;
  }
  .page2_personName__2e93X{
    color: #0B1165;
    font-family: Prata;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
  
  .page2_content__99P_7 {
    display: flex;
    margin-top: 88px;
  }
  .page2_sectionContent__2pSLk {
    display: grid;
    grid-template-columns: 150px auto; /* Adjust the width of the label column as needed */

  }
  .page2_address__2ekS2{
    margin-bottom: 2px;
  }
  
  .page2_detailItem__1-_Wa {
    display: contents;
    color: #000;
    font-family: Prata;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 3px;
  }
  
  
  .page2_value__3l3E4 {
    text-align: left; /* Aligns the value to the left */
  }
  

  .page2_mainInfo__1TI-_ {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 100%;
    margin-left: 0;
    text-align: left;
  }
  

  
  
  .page2_details__1BpDT {
    display: flex;
    padding-left: 45px;
    flex-direction: column;
    align-items: flex-start;
    font-size: 10px;
    color: #000;
  }
  
 
  
  .page2_sectionTitle__WPxli {
    color: #132A7B;
    font-family: Prata;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom:5px;
    margin-top: 15px;
  }
  


  
  .page2_sideInfo__3xtx8 {
    display: flex;
    line-height: normal;
    grid-gap: 5px;
    gap: 5px;
    width: 100%;
    justify-content: space-between;
  }
  

  
  .page2_sideContent__1OM0z {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 31px;
  }
  

  .page2_profilePic__2KtNC {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 20px;
    align-self: flex-start;
    z-index: 10;
    margin-top: -16px;
  }
  
  .page2_sideImage__3Celh {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 100px;
    margin-top: 538px;
    max-width: 100%;
  }
  
  @media (max-width: 768px) {
    .page2_card__1uXon{
      transform: scale(0.7); /* scales content without affecting PDF output */
      transform-origin: top center;
    }
    }

@media (max-width: 768px) {
  .page3_card__FapC0{
    transform: scale(0.7); /* scales content without affecting PDF output */
    transform-origin: top center;
  }
  }
.page3_card__FapC0 {
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  width: 595px;
  height: 842px;
    overflow: hidden;
    position: relative;
  }
  .page3_personName__1izKI{
    color: #0B1165;
    font-family: Prata;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .page3_content__3EEL3 {
    display: flex;
    margin-top: 88px;
  }
  .page3_sectionContent__-GGJr {
    display: grid;
    grid-template-columns: 150px auto; /* Adjust the width of the label column as needed */
    grid-gap: 1px;
    gap: 1px;

    color: #000;
    font-family: Prata;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .page3_address__QwCCJ{
    margin-bottom: 2px;
  }
  
  .page3_detailItem__1Aesw {
    display: contents;
    color: #000;
    font-family: Prata;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 3px;
  }
  
  
  .page3_value__3Ty-L {
    text-align: left; 
    color: #000;
font-family: Prata;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 5px;
max-width: 500px;
padding-right: 20px;
  }
  
  @media (max-width: 991px) {
    .page3_content__3EEL3 {
      flex-direction: column;
      align-items: stretch;
      grid-gap: 0;
      gap: 0;
    }
  }
  
  .page3_mainInfo__347r1 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    padding-left: 45px;
    text-align: left;
  }
  
  
  .page3_sectionTitle__2jPh7 {
    color: #132A7B;
    font-family: Prata;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  

  
  @media (max-width: 991px) {
    .page3_sectionContent__-GGJr {
      margin-right: 10px;
    }
  }

  
  .page3_extraItem__2BvYV{
    display: flex;
    justify-content: flex-start;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .page3_extraImages__p1i9y{
    height: 95px;
    width: 95px;
    object-fit: cover;

  }
.cvpreview_pageWrapper__3SwAJ {
    display: flex;
    flex-direction: column;
    align-items: center; 
    width: 100%;
    justify-content: center;
}

.cvpreview_CVpreview__1Gokt {
    margin-top: 84px;
}

.cvpreview_pagetitle__n1hke {
    color: #000;
    font-family: "Playfair Display";
    font-size: 40px;
    font-weight: 800;
    line-height: normal;
}

.cvpreview_pagecontainer__19NQh {
    display: flex;
    justify-content: space-evenly;
    margin: 24px 0px 30px 0px;
    position: relative;
}

.cvpreview_arrowLeft__8-t0n, .cvpreview_arrowRight__3xMnl {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.55);
    background: rgba(255, 153, 0, 0.52);
    cursor: pointer;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.cvpreview_arrowLeft__8-t0n {
    left: 10px;
}

.cvpreview_arrowRight__3xMnl {
    right: 10px;
}

.cvpreview_pageIndex__9WeIs {
    text-align: center;
    margin-top: 10px;
    color: #000;
    font-family: "Playfair Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media (max-width: 768px) {
.cvpreview_arrowLeft__8-t0n, .cvpreview_arrowRight__3xMnl {
    width: 30px;
    height: 30px;
}
.cvpreview_pageIndex__9WeIs{
    margin-top: -211px;
}
}
.cvpreview_buttonContainer__14Gwl{
    display: flex;
    justify-content: space-around;
}
.cvpreview_downloadButton__1ZcV9 {
    border: none;
    border-radius: 16px;
    background: #FFC764;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 308px;
    height: 54px;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.5px;
    text-transform: capitalize;
    margin-bottom: 40px;
    margin-right: 13px;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 13px;
    word-break: break-word;
}

.cvpreview_downloadButton__1ZcV9:hover {
    background-color: #e68a00;
}


.VenusLoginStyle_loginContainer__2ie3F {
  background-color: #211d18;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  min-height: 100vh; /* Full viewport height */
}

.VenusLoginStyle_loginCard__2fjmF {
  border-radius: 20px;
  background-color: #640a29;
  width: 900px; /* Smaller width for a cleaner layout */
  max-width: 100%; /* Responsive: don't exceed screen size */
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for better appearance */
}

.VenusLoginStyle_cardContent__1DWI- {
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  gap: 20px; /* Reduced gap between form and image */
}

.VenusLoginStyle_imageColumn__3fI22 {
  flex: 1 1; /* Allow image column to adapt to space */
}

.VenusLoginStyle_loginImage__1euQO {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  height: 100%;
}

.VenusLoginStyle_formColumn__2-oM_ {
  flex: 1 1; /* Allow form column to adapt to space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px;
}

.VenusLoginStyle_loginForm__KMuhh {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.VenusLoginStyle_formHeader__PgPi2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  grid-gap: 10px;
  gap: 10px;
}

.VenusLoginStyle_headerText__3miHs {
  color: var(--Neutrals-Colors-100, #FFF);
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.VenusLoginStyle_premiumText__2Iqsj {
  color: var(--Neutrals-Colors-100, #FFF);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 200% */
  text-transform: uppercase;
  position: relative;
}
/* Wave animation for the premium icon */
@keyframes VenusLoginStyle_wave__1a6nX {
  0% {
    transform: rotate(0deg); /* Starting position */
  }
  25% {
    transform: rotate(15deg); /* Rotate slightly to the right */
  }
  50% {
    transform: rotate(0deg); /* Back to the original position */
  }
  75% {
    transform: rotate(-15deg); /* Rotate slightly to the left */
  }
  100% {
    transform: rotate(0deg); /* Return to original */
  }
}
.VenusLoginStyle_premiumIcon__1ashD{
  width: 30px;
  height: 30px;
  position: absolute;
  right: -21px;
  top: -7px;
  transition: transform 0.3s ease;
  animation: VenusLoginStyle_wave__1a6nX 2s infinite ease-in-out; 
}
.VenusLoginStyle_inputLabel__36YrH {
  color: var(--Neutrals-Colors-100, #FFF);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 10px 0px;
}

.VenusLoginStyle_inputField__1r_4e {
  border-radius: 8px;
  background-color: #eae6e6;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
}

.VenusLoginStyle_loginButton__3oqxJ {
  border-radius: 8px;
  background-color: #28221f;
  color: #ffffff;
  margin-top: 30px;
  padding: 15px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
}

.VenusLoginStyle_loginButton__3oqxJ:hover {
  background-color: #4a1c24; /* Button hover effect */
}

@media (max-width: 991px) {
  .VenusLoginStyle_loginContainer__2ie3F{
    min-height: auto;
  }
  .VenusLoginStyle_loginCard__2fjmF {
    width: 100%; /* Full width on smaller screens */
    padding: 20px;
  }

  .VenusLoginStyle_cardContent__1DWI- {
    flex-direction: column;
  }
   .VenusLoginStyle_loginImage__1euQO{
    max-height: 400px;
   }

  .VenusLoginStyle_inputLabel__36YrH {
    margin-top: 15px;
  }

  .VenusLoginStyle_loginButton__3oqxJ {
    margin-top: 20px;
    padding: 12px;
    font-size: 16px;
  }
}

.userDashboard_userDashboard__Ibkcj {
    background-color: #211d18;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .userDashboard_dashboardContent__2obdL {
    border-radius: 15px;
    background-color: #fff;
    align-self: center;
    display: flex;
    margin: 55px;
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
    overflow: hidden;
    padding: 79px 70px;
  }
  
  .userDashboard_profileSection__13O_h {
    width: 956px;
    max-width: 100%;
  }
  
  .userDashboard_profileLayout__2Vq_T {
    grid-gap: 20px;
    gap: 20px;
    display: flex;
  }
  
  .userDashboard_profileImageColumn__3OmwH {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 33%;
    margin-left: 0;
  }
  
  .userDashboard_profileImage__3l9UE {
    border-radius: 15px;
    background-color: #100000;
    display: flex;
    width: 300px;
    height: 300px;
    aspect-ratio: 1;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .userDashboard_logoutButton__2G7ry {
    background-color: #610d0d;
    justify-content: center;
  }
  
  .userDashboard_logoutButton__2G7ry:hover {
    background-color: #ac0b0b; 
    color: #F1E4E8;
  }
  
  .userDashboard_profileInfoColumn__2HswR {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 67%;
    margin-left: 20px;
  }
  
  .userDashboard_profileInfo__82R8e {
    display: flex;
    margin-top: 77px;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    font-family: Poppins, sans-serif;
    color: #000;
  }
  
  .userDashboard_profileName__3GYzX {
    font-size: 35px;
    font-weight: 700;
  }
  
  .userDashboard_profileDetails__1G43H {
    display: flex;
    justify-content: flex-start;
    grid-gap: 30px;
    gap: 30px;
    font-size: 20px;
    font-weight: 300;
    align-self: stretch;
    margin-top: 15px;
    text-align: left;
    font-family: Poppins;
  }
  
  .userDashboard_editButton__21JWK {
    border-radius: 10px;
    background-color: #000;
    margin-top: 26px;
    overflow: hidden;
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    padding: 6px 54px;
    cursor: pointer;
  }
  
  .userDashboard_actionSection__1Rsdj {
    align-self: end;
    display: flex;
    margin-top: 61px;
    max-width: 100%;
    grid-gap: 40px 58px;
    gap: 40px 58px;
    color: #fff;
    white-space: nowrap;
    flex-wrap: wrap;
    font: 700 30px Poppins, sans-serif;
  }
  
  .userDashboard_actionButton__3fexW {
    color: #fff;
    border-radius: 15px;
    display: flex;
    grid-gap: 40px 91px;
    gap: 40px 91px;
    overflow: hidden;
    flex: 1 1;
    flex-basis: auto;
    padding: 9px 25px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .userDashboard_actionButtonContent__3owrV {
    display: flex;
    grid-gap: 38px;
    gap: 38px;
  }
  
  .userDashboard_actionIcon__h2q3n {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 50px;
    align-self: flex-start;
  }
  
  .userDashboard_actionText__3dGie {
    flex-basis: auto;
  }
  
  .userDashboard_matchButton__2PBWP {
    background-color: #171717;
  }
  
  .userDashboard_supportButton__hwwRO {
    background-color: #5671d0;
  }
  
  @media (max-width: 991px) {
    .userDashboard_dashboardContent__2obdL {
      max-width: 100%;
      margin-top: 40px;
      padding: 40px 20px;
    }
  
    .userDashboard_profileLayout__2Vq_T {
      flex-direction: column;
      align-items: stretch;
      grid-gap: 40px;
      gap: 40px;
    }
  
    .userDashboard_profileImageColumn__3OmwH,
    .userDashboard_profileInfoColumn__2HswR {
      width: 100%;
      margin-left: 0;
    }
  
    .userDashboard_profileImage__3l9UE {
      margin-top: 0;
    }
  
    .userDashboard_profileInfo__82R8e {
      margin: 30px 39px;
    }
  
    .userDashboard_profileDetails__1G43H {
      max-width: 100%;
    }
  
    .userDashboard_editButton__21JWK {
      align-self: center;
      padding: 10px 20px;
    }
  
    .userDashboard_actionSection__1Rsdj {
      margin: 40px;
      white-space: initial;
      justify-content: center;
      font-size: 30px;
    }
    .userDashboard_actionIcon__h2q3n{
      width: 40px;
    }
    .userDashboard_actionButton__3fexW {
      padding: 15px 20px;
      white-space: initial;
      width: 60%;
    }
  
    .userDashboard_actionButtonContent__3owrV {
      justify-content: center;
    }
  }
/* matchListstyle.module.css */
.matchListstyle_userDashboard__3Wa6g {
    background-color: #211d18;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .matchListstyle_cardcontainer__14Dle {
    height: 509px;
    border-radius: 15px;
    background: #FFF;
    margin: 55px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    padding: 40px;
    overflow-y: auto;
  }
  .matchListstyle_title__36TRX{
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    margin-left: 72px;
    text-decoration: none;
    margin-top: 10px;
  }
  
  .matchListstyle_profileCard__1Kdtp {
    display: flex;
    justify-content: space-around;
    background-color: #1b1b1b;
    border-radius: 20px;
    padding: 20px 99px 20px 20px;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    color: #fff;
    max-width: 100%;
    margin: 0 72px;
  }
  
  .matchListstyle_userInfo__2F21H {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
  }
  
  .matchListstyle_avatarSection__2pvy6 {
    background-color: #d9d9d9; /* Placeholder background for image */
    border-radius: 12px;
    height: 80px;
    width: 80px;
  }
  
  .matchListstyle_avatarSection__2pvy6 img {
    border-radius: 12px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .matchListstyle_detailsSection__UoU_c {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
  
  .matchListstyle_exampleCodeName__sl07A {
    font-weight: bold;
    font-size: 18px;
    font-family: 'Nunito', sans-serif; /* You already prefer this font */
  }
  
  .matchListstyle_ageHeightStatus__3WUyg {
    font-size: 14px;
    line-height: 1.5;
  }
  
  .matchListstyle_actionsSection__1IJ8l {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
  }
  
  .matchListstyle_viewProfile__1lPEa {
    display: inline-flex;
    padding: 11px 62px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #BDCCFF;
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
  }
  
  .matchListstyle_requestChatContainer__3jxf_ {
    display: inline-flex;
    padding: 11px 39px 11px 17px;
    align-items: flex-start;
    grid-gap: 29px;
    gap: 29px;
    border-radius: 10px;
    background: #454545;
    cursor: pointer;
  }
  
  .matchListstyle_requestChat__3y0Dx {
    background: none;
    border: none;
    color: #D9D9D9;
    font-family: Poppins;
    cursor: pointer;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .matchListstyle_chatImg__2lceP {
    width: 24px;
    height: 24px;
  }
  
  .matchListstyle_heartIcon__3rERq {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #ffffff; /* Initially white */
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000000"><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>');
            mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000000"><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>'); /* Heart shape */
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
  }
  
  .matchListstyle_liked__3PUdt {
    background-color: #ff5a5a; /* Change to red when liked */
  }
  
  /* For mobile responsiveness */
@media (max-width: 768px) {
    .matchListstyle_cardcontainer__14Dle {
      margin: 10px 0 0 0;  
      padding: 0; 
      width: 100%; 
    }
  
    .matchListstyle_profileCard__1Kdtp {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px; 
      margin: 0 20px;      
    }
  .matchListstyle_actionsSection__1IJ8l{
    margin-top: 10px;
  }
    .matchListstyle_viewProfile__1lPEa {
      padding: 9px 10px; /* Adjust button size */
    }
  
    .matchListstyle_requestChatContainer__3jxf_ {
      padding: 9px 10px; 
      grid-gap: 10px; 
      gap: 10px;
    }
  
    .matchListstyle_heartIcon__3rERq {
      width: 30px;  /* Adjust heart icon size */
      height: 30px; /* Adjust heart icon size */
    }
  }
  
  .matchListstyle_disabled__26DTC {
    pointer-events: none;
    filter: grayscale(100%);
    opacity: .7;
  }
  
  

.profilePreview_userDashboard__2zJl2 {
    background-color: #211d18;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
  }
  .profilePreview_profileViewContainer__FPwtJ {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 40px;
    width: 80%;
  }
  
  .profilePreview_headerSection__37nud {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
    align-items: flex-start;
  }
  
  .profilePreview_profileViewingText__3bE-p {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    text-align: left;
    width: 100%;
    text-decoration: none;
  }
  
  .profilePreview_actionIcons__BePY- {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: column;
  }
  
  .profilePreview_iconButton__-vSJh {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .profilePreview_iconButton__-vSJh img {
    width: 24px;
    height: 24px;
  }
  

  
  .profilePreview_actionsContainer__3F6jF {
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  
  .profilePreview_print__DzEvR, .profilePreview_share__3F6MN {
    border: none;
    padding: 12px 12px;
    border-radius: 15px;
    display: inline-flex;
    align-items: center;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .profilePreview_print__DzEvR{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #BDCCFF;
  }
.profilePreview_share__3F6MN{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #C0FFBD;
}
  .profilePreview_requestChat__s5yzI {
    color: #fff;
    display: inline-flex;
    padding: 7px 18px 8px 12px;
    align-items: center;
    grid-gap: 13px;
    gap: 13px;
    border-radius: 10px;
    background: #211D18;
    border: none;
  }
  
  .profilePreview_interested__31zLG {
    background: #211D18;
    color: #fff;
    display: inline-flex;
    padding: 7px 18px 8px 12px;
    align-items: center;
    grid-gap: 13px;
    gap: 13px;
    border-radius: 10px;
    border: none;
  }
  
  .profilePreview_notInterested__2MtQ7 {
    background: #211D18;
    color: #fff;
    display: inline-flex;
    padding: 7px 18px 8px 12px;
    align-items: center;
    grid-gap: 13px;
    gap: 13px;
    border-radius: 10px;
    border: none;
  }
  .profilePreview_biopreview__3HTin{
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    width: 100%;
  }
    
  @media (max-width: 768px) {
    .profilePreview_profileViewContainer__FPwtJ{
      width: 100%;
    }
    .profilePreview_profileViewingText__3bE-p{
      text-align: center;
    }
    .profilePreview_headerSection__37nud {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 20px;
      align-items: flex-start;
    }

    .profilePreview_actionIcons__BePY-{
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }
    }


    /* Modal Overlay */
.profilePreview_modalOverlay__3p0nr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: profilePreview_fadeIn__284Ki 0.3s ease-in-out;
}

/* Modal Content */
.profilePreview_modalContent__1gZ4m {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  position: relative;
  animation: profilePreview_slideDown__hi-bz 0.3s ease-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Close Button */
.profilePreview_closeButton__2FmgA {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  transition: color 0.3s;
}

.profilePreview_closeButton__2FmgA:hover {
  color: #333;
}

/* Modal Text */
.profilePreview_modalContent__1gZ4m p {
  font-size: 16px;
  margin: 20px 0;
  color: #444;
}

/* Modal Actions */
.profilePreview_modalActions__1XOEq {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

/* Yes Button */
.profilePreview_yesButton__2RJ6L {
  background-color: #ff5c5c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.profilePreview_yesButton__2RJ6L:hover {
  background-color: #e04444;
}

/* No Button */
.profilePreview_noButton__BdhFr {
  background-color: #ddd;
  color: #444;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.profilePreview_noButton__BdhFr:hover {
  background-color: #ccc;
}

/* Animations */
@keyframes profilePreview_fadeIn__284Ki {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes profilePreview_slideDown__hi-bz {
  from {
      transform: translateY(-20px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}


.venusPremiumStyle_container__N_5Lv {
  background: #211D18;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.venusPremiumStyle_topCoverContainer__1qSpK{
  position: relative;
}
.venusPremiumStyle_top_cover__2Qr9d {
  border-radius: 0 0 40px 40px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-height: 580px;
  z-index: 0;
}
.venusPremiumStyle_lotapata__32W8v{
  position: absolute;
  bottom: -83px;
  left: 13px;
  width: 250px;
  opacity: 0.5;
}
.venusPremiumStyle_venusrings__1dDA4{
  position: absolute;
  bottom: -98px;
  right: 18px;
  width: 250px;
}
.venusPremiumStyle_hero__SbmhD {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  margin-bottom: 37px;
}


.venusPremiumStyle_heroTitle__uZsNY {
  border-radius: 15px;
  background: linear-gradient(90deg, #FFC05B 0%, #FB9D04 100%);
  color: #000;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 30px 50px;
  margin-top: -50px;
  z-index: 999;
}

.venusPremiumStyle_whatVenus__18kkU{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.venusPremiumStyle_sectionTitle__2nexn {
  align-self: start;
  color: #EA2F6F;
  text-align: right;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  margin: 30px 0 15px 0;
}

.venusPremiumStyle_sectionTitleText__3hhk9 {
  flex-grow: 1;
  margin: auto 0;
}

.venusPremiumStyle_sectionTitleIcon__2ceWI {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 30px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.venusPremiumStyle_descriptionSection__1sHxJ {
  align-self: center;
  z-index: 10;
  display: flex;
  width: 100%;
  max-width: 1075px;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  text-align: center;
}

.venusPremiumStyle_description__rGlen {
  border-radius: 15px;
  background: linear-gradient(90deg, #FFC05B 0%, #FB9D04 100%); 
  color: #1C1B1F;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 40px;
}


.venusPremiumStyle_benefits__3nlsq {
  border-radius: 15px;
  background: linear-gradient(90deg, #FFC05B 0%, #FB9D04 100%); 
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 40px;
}


.venusPremiumStyle_contactInfo__3-s1G {
  background-color: #ea2f6f;
  z-index: 10;
  margin-top: 33px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  padding: 10px;
  font: 400 21px Inter, sans-serif;
}

.venusPremiumStyle_contactInfoBold___7aOZ {
  font-weight: 500;
}

.venusPremiumStyle_howvenuscontainer__34w8_ {
  background: #211D18;
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  padding-top: 80px;
}

.venusPremiumStyle_closeIcon__1J6C4 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
}

.venusPremiumStyle_contentWrapper__j9Nqr {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.venusPremiumStyle_headerSection__1fE9_ {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.venusPremiumStyle_titleContainer__2snuX {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.venusPremiumStyle_mainTitle__ev6so {
  color: var(--Cards-Backgrounds-BG-Color-1, #FFF);
  text-align: center;
  font: 600 40px Playfair Display, -apple-system, Roboto, Helvetica, sans-serif;
  margin: 0;
}

.venusPremiumStyle_logoContainer__1C_j4 {
  align-self: center;
  display: flex;
}

.venusPremiumStyle_logo1__1U2kH {
  aspect-ratio: 1.69;
  object-fit: contain;
  object-position: center;
  width: 240px;
}

.venusPremiumStyle_stepsContainer__2cyab {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.venusPremiumStyle_stepsWrapper__1vsoB {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.venusPremiumStyle_stepsList__8zjNl {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.venusPremiumStyle_verticalDivider__10-yG {
  background: var(--rn-53-themes-net-bone, #ddcebc);
  position: absolute;
  z-index: 0;
  display: flex;
  min-height: 1370px;
  width: 1px;
  right: 50%;
  top: 0px;
  bottom: 0px;
}

.venusPremiumStyle_stepItem__1UP6n {
  z-index: 0;
  display: flex;
  width: 100%;
  padding-bottom: 50px;
  flex-direction: column;
  justify-content: flex-start;
}

.venusPremiumStyle_stepContent__1rIWT {
  display: flex;
  width: 100%;
  justify-content: center;
  grid-gap: 10%;
  gap: 10%;
}

.venusPremiumStyle_stepImageContainer__3HbDP {
  display: flex;
  width: 34%;
  align-items: flex-start;
  justify-content: flex-end;
}

.venusPremiumStyle_stepImage__1SakY {
  aspect-ratio: 1;
  object-position: center;
  width: 128px;
  max-width: 450px;
}

.venusPremiumStyle_stepTextContainer__3_Ry4 {
  display: flex;
  width: 34%;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  color: var(--Cards-Backgrounds-BG-Color-1, #FFF);
  justify-content: flex-start;
  text-align: left;
}

.venusPremiumStyle_stepTitle__2dJH6 {
  color: var(--Cards-Backgrounds-BG-Color-1, #FFF);
  font-family: "Playfair Display";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
}

.venusPremiumStyle_stepSubtitle__157XE {
  color: var(--rn-53-themes-net-razzmatazz, #eb0359);
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 11px;
}

.venusPremiumStyle_stepDescription__1L3Ba {
  margin-top: 11px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  padding: 10px 0 15px;
}

.venusPremiumStyle_stepItemReverse__1dhoy .venusPremiumStyle_stepTextContainer__3_Ry4 {
  text-align: right;
}

.venusPremiumStyle_stepItemReverse__1dhoy .venusPremiumStyle_stepSubtitle__157XE {
  align-self: flex-end;
}

.venusPremiumStyle_stepItemReverse__1dhoy .venusPremiumStyle_stepImageContainer__3HbDP {
  justify-content: flex-start;
}

.venusPremiumStyle_backgroundBorderShadow__2lif1 {
  border-radius: 50px;
  border: 4px solid var(--rn-53-themes-net-nero, #fff);
  background: var(--rn-53-themes-net-west-coast, #66451c);
  box-shadow: 0px 0px 10px 0.6px rgba(40, 30, 20, 0.08);
  position: absolute;
  z-index: 0;
  display: flex;
  width: 17px;
  height: 17px;
    /* Centering the element horizontally */
    left: 50%;
    transform: translateX(-50%);
}

/* Responsive Styles for Mobile */

@media (max-width: 991px) {
  .venusPremiumStyle_container__N_5Lv {
    max-width: 100%;
  }

  .venusPremiumStyle_hero__SbmhD {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }


  .venusPremiumStyle_heroTitle__uZsNY {
    max-width: 100%;
    font-size: 16px;
    padding: 15px;
    margin: -50px 1.5rem 1.5rem 1.5rem;
  }
  .venusPremiumStyle_whatVenus__18kkU{
    margin: 0 1.5rem;
  }
  .venusPremiumStyle_whatExpect__ti4iM{
    margin: 0 1.5rem;
  }
  .venusPremiumStyle_sectionTitle__2nexn {
    margin-top: 20px;
  }
  .venusPremiumStyle_sectionTitleText__3hhk9{
    font-size: 16px;
  }
  .venusPremiumStyle_descriptionSection__1sHxJ {
    max-width: 100%;
  }

  .venusPremiumStyle_description__rGlen {
    max-width: 100%;
    padding: 20px;
    font-size: 15px;
  }

  .venusPremiumStyle_benefits__3nlsq {
    max-width: 100%;
    padding: 20px;
    font-size: 15px;
  }

  .venusPremiumStyle_howvenuscontainer__34w8_{
    padding-top: 50px;
  }
  .venusPremiumStyle_mainTitle__ev6so{
    font-size: 20px;
  }
  .venusPremiumStyle_logo1__1U2kH{width: 160px;}
  .venusPremiumStyle_stepImageContainer__3HbDP {
      width: 42%;
    }
  .venusPremiumStyle_stepTextContainer__3_Ry4{
    width: 42%;
  }
  .venusPremiumStyle_stepTitle__2dJH6{
    font-size: 18px;
    line-height: normal;
  }
  .venusPremiumStyle_stepSubtitle__157XE{
    font-size: 8px;
    line-height: normal;
  }
  .venusPremiumStyle_stepDescription__1L3Ba{
    font-size: 10px;
    line-height: normal;
  }
  .venusPremiumStyle_contactInfo__3-s1G{
    font-size: 16px;
  }
}
/* General container styling */
.blogDetails_pageContainer__1WJel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    background: #f0f4f8;
    margin-top: 40px;
  }
  
  /* Blog Card styling */
  .blogDetails_blogCard__2F4Nt {
    max-width: 900px;
    width: 100%;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .blogDetails_blogCard__2F4Nt:hover {
    transform: translateY(-5px);
  }
  
  /* Hero section styling */
  .blogDetails_heroSection__1b8P5 {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
  }
  
  .blogDetails_heroImage__3bzbA {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .blogDetails_overlay__1VfQW {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    background: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    border-radius: 10px; 
    padding: 5px 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
  }
  
  
  .blogDetails_title__2a5Al {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  /* Content section styling */
  .blogDetails_contentSection__3-nDU {
    padding: 30px;
    line-height: 1.8;
    font-size: 1rem;
    color: #333;
    font-family: 'Poppins', sans-serif;
  }
  
  .blogDetails_textContent__slsr5 {
    margin-bottom: 20px;
    word-wrap: break-word;
  }
  
  /* Author section styling */
  .blogDetails_authorSection__35cdi {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #f9fafb;
    border-top: 1px solid #ddd;
  }
  
  .blogDetails_authorImage__3iiMl {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
  }

.messenger-container {
    display: flex;
    height: 100vh;
    max-width: 100vw;
    overflow: hidden;
}

.chat-sidebar {
    width: 30%;
    max-width: 300px;
    background-color: #f0f0f0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    transition: transform 0.3s ease;
}

.chat-sidebar h3 {
    text-align: center;
    margin-bottom: 20px;
}

.chat-sidebar ul {
    list-style: none;
    padding: 0;
}

.chat-sidebar ul li {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    position: relative;
}

.chat-sidebar ul li.active {
    background-color: #e4f7e4;
}

.chat-sidebar ul li:hover {
    background-color: #ddd;
}

.chat-avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.chat-info {
    text-align: left;
}

.chat-name {
    font-weight: bold;
}

.chat-last-message {
    font-size: 0.9em;
    color: #666;
}

.active-indicator {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
}

.chat-window {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    background-color: #f4f5fb;
    transition: transform 0.3s ease;
}

.chat-header {
    padding: 10px;
    background-color: #28292b;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat-header h3 {
    margin: 0;
}

.back-button {
    background: none;
    border: none;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
}

.chat-body {
    flex: 1 1;
    padding: 15px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.message {
    padding: 10px;
    margin: 5px 0;
    border-radius: 20px;
    max-width: 60%;
    display: flex;
    align-items: center;
}

.message-avatar {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.message.user {
    background-color: #6e91a0;
    color: white;
    align-self: flex-end;
}
.message.user p{
    text-align: right;
}

.message.bot {
    background-color: #e4e6eb;
    color: black;
    align-self: flex-start;
}

.message.bot p{
    text-align: left;
}
.message-text {
    white-space: normal;        
    word-wrap: break-word;           
    max-width: 150px;             
    padding: 10px;        
    border-radius: 10px; 
    text-align: right;  
  }
  

.chat-footer {
    display: flex;
    padding: 10px;
    background-color: white;
}

.chat-footer input {
    flex: 1 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.emoji-picker {
    position: absolute;
    bottom: 60px; /* Adjust as needed */
  }

.chat-footer button {
    padding: 10px 15px;
    margin-left: 10px;
    background-color: #28292b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chat-placeholder {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #aaa;
}

@media screen and (max-width: 768px) {
    .chat-sidebar {
        width: -webkit-fill-available;
        max-width: none;
        position: absolute;
        z-index: 1000;
        transform: translateX(0%);
    }
    .chat-last-message {
        overflow: hidden;                    
        white-space: nowrap;                 
        text-overflow: ellipsis;            
        max-width: 250px;                    
        display: inline-block;               
        color: #333; 
        font-size: 14px;
      }

    .chat-window {
        flex: 1 1;
    }

    .show {
        transform: translateX(-100%);
    }
}
@media screen and (max-width: 768px) {
    .chat-window.show {
        transform: translateX(0);
    }
}
.chat-last-message {
    overflow: hidden;                    
    white-space: nowrap;                 
    text-overflow: ellipsis;            
    max-width: 250px;                    
    display: inline-block;               
    color: #333;  
    font-size: 14px; 
  }
  
  .cs-button--attachment {
    display: none !important;
  }
  

  
.report-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s forwards;
  }
  
  .report-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: slideIn 0.3s forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-20px);
    }
  }
  
  .fade-out {
    animation: fadeOut 0.3s forwards;
  }
  
  .slide-out {
    animation: slideOut 0.3s forwards;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: #555;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  select,
textarea,
input[type="file"] {
  width: 100%; /* Set inputs to 100% width */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding doesn't affect total width */
}

textarea {
  resize: vertical; /* Allow vertical resizing only */
}
  
  .form-group select:focus,
  .form-group textarea:focus,
  .form-group input:focus {
    border-color: #007BFF;
  }
  
  .image-preview {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .image-preview-item {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .image-preview-item img {
    max-width: 100px;
    max-height: 100px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .remove-image-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 0, 0, 0.8);
    border: none;
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    font-size: 1.2em;
    text-align: center;
    line-height: 25px;
  }
  
  .submit-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
