.messenger-container {
    display: flex;
    height: 100vh;
    max-width: 100vw;
    overflow: hidden;
}

.chat-sidebar {
    width: 30%;
    max-width: 300px;
    background-color: #f0f0f0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    transition: transform 0.3s ease;
}

.chat-sidebar h3 {
    text-align: center;
    margin-bottom: 20px;
}

.chat-sidebar ul {
    list-style: none;
    padding: 0;
}

.chat-sidebar ul li {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    position: relative;
}

.chat-sidebar ul li.active {
    background-color: #e4f7e4;
}

.chat-sidebar ul li:hover {
    background-color: #ddd;
}

.chat-avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.chat-info {
    text-align: left;
}

.chat-name {
    font-weight: bold;
}

.chat-last-message {
    font-size: 0.9em;
    color: #666;
}

.active-indicator {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
}

.chat-window {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f4f5fb;
    transition: transform 0.3s ease;
}

.chat-header {
    padding: 10px;
    background-color: #28292b;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat-header h3 {
    margin: 0;
}

.back-button {
    background: none;
    border: none;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
}

.chat-body {
    flex: 1;
    padding: 15px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.message {
    padding: 10px;
    margin: 5px 0;
    border-radius: 20px;
    max-width: 60%;
    display: flex;
    align-items: center;
}

.message-avatar {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.message.user {
    background-color: #6e91a0;
    color: white;
    align-self: flex-end;
}
.message.user p{
    text-align: right;
}

.message.bot {
    background-color: #e4e6eb;
    color: black;
    align-self: flex-start;
}

.message.bot p{
    text-align: left;
}
.message-text {
    white-space: normal;        
    word-wrap: break-word;           
    max-width: 150px;             
    padding: 10px;        
    border-radius: 10px; 
    text-align: right;  
  }
  

.chat-footer {
    display: flex;
    padding: 10px;
    background-color: white;
}

.chat-footer input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.emoji-picker {
    position: absolute;
    bottom: 60px; /* Adjust as needed */
  }

.chat-footer button {
    padding: 10px 15px;
    margin-left: 10px;
    background-color: #28292b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chat-placeholder {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #aaa;
}

@media screen and (max-width: 768px) {
    .chat-sidebar {
        width: -webkit-fill-available;
        max-width: none;
        position: absolute;
        z-index: 1000;
        transform: translateX(0%);
    }
    .chat-last-message {
        overflow: hidden;                    
        white-space: nowrap;                 
        text-overflow: ellipsis;            
        max-width: 250px;                    
        display: inline-block;               
        color: #333; 
        font-size: 14px;
      }

    .chat-window {
        flex: 1;
    }

    .show {
        transform: translateX(-100%);
    }
}
@media screen and (max-width: 768px) {
    .chat-window.show {
        transform: translateX(0);
    }
}
.chat-last-message {
    overflow: hidden;                    
    white-space: nowrap;                 
    text-overflow: ellipsis;            
    max-width: 250px;                    
    display: inline-block;               
    color: #333;  
    font-size: 14px; 
  }
  
  .cs-button--attachment {
    display: none !important;
  }
  

  