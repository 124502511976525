.container {
    width: 80%;
    margin: 5rem auto;
    font-family: Nunito;
  }
  .title{
    padding: 10px 0;
    color: #484848;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .row {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .col {
    flex: 1;

  }
  
  .inlineFields {
    display: flex;
    gap: 1rem;
  }
  
  .smallField {
    flex: 1;
  }
  
  .largeField {
    flex: 2;
  }
  
  .formLabel {
    margin-bottom: 0.5rem;
    display: block;
    color: #484848;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .formControl {
    width: 100%;
    padding: 0.638rem;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box; 
  }
  
  .btnGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .btnCheck {
    display: none;
    
  }
  
  .btnLabel {
    padding: 0.5rem 1rem;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    border-radius: 5px;
    border: 1px solid #E5E5E5;
    background: #FFF;
    color: #454545;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  

  .btnCheck:checked + .btnLabel {
    background-color: #607D8B;
  color: white;
  }
  
  .submitButton,
  .backButton {
    padding: 0.75rem 4rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #070e16;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 1rem;
  }
  .buttonGroup {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .submitButton:hover,
  .backButton:hover {
    background-color: #1b1b28;
  }
  @media (max-width: 768px) {
   .row{
    flex-direction: column;
   }
   .submitButton,
   .backButton {
     padding: 0.75rem 2rem;
   }
  }
