/* PartnerPreferences.module.css */

.container {
    width: 80%;
    margin: 5rem auto;
    font-family: Nunito;
  }
  
  .title{
    padding: 10px 0;
    color: #484848;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .column {
    flex: 1;
    min-width: 250px;
  }
  
  .label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
  }
  
 .input {
    width: 100%;
    padding: 0.638rem;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box; 
  }
  
  .inputGroup {
    display: flex;
    gap: 10px;
  }
  
  .districtGroup {
    display: flex;
    gap: 10px;
  }
  
  .imageUploadContainer {
    background: #D9D9D9;
    border-radius: 5px;
    min-height: 150px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    flex-wrap: wrap;
  }
  
  .imageInputContainer {
    display: flex;
    align-items: center;
  }
  
  .fileInput {
    display: none;
  }
  
  .imageInputLabel {
    border-radius: 15px;
    border: 1px solid #000;
    background: #FF6584;
    color: var(--Neutrals-Colors-100, #FFF);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0.5625rem 3rem;
  }
  
  .imagePreviewContainer {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
    align-items: center;
  }
  
  .imageWrapper {
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }
  
  .imageThumbnail {
    width: 100px;
    height: 98px;
    object-fit: cover;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #e9f2f6;
    cursor: pointer;
    font-size: 1.5rem; 
  }
  .buttonGroup {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .submitButton,
  .backButton {
    padding: 0.75rem 4rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #070e16;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 1rem;
  }
  
  .submitButton:hover,
  .backButton:hover {
    background-color: #1b1b28;
  }
  @media (max-width: 768px){
    .submitButton,
    .backButton {
      padding: 0.75rem 2rem;
    }

  }