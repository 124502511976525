@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700&display=swap');

.testimonialSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  color: var(--rn-53-themes-net-west-coast, #66451c);
  font-weight: 400;
  position: relative;
}
.grass {
  width: 100%;            /* Makes the image take up 100% of the width of the parent div */
  height: 100%;           /* Makes the image take up 100% of the height of the parent div */
  object-fit: cover;      /* Ensures the image covers the entire area, maintaining aspect ratio and cropping if necessary */
  object-position: center; /* Centers the image within the div */
}

.shadowImageContainer {
  position: relative;
  object-fit: contain;
  object-position: center;
}

.shadowImage {
  width: 100%;
}

.toptitle {
  color: var(--rn-53-themes-net-west-coast, #66451C);
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 5px;
  font-family: "Playfair Display";
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 55.2px; /* 120% */
}

.testimonialList {
  display: flex;
  justify-content: space-around;
  padding: 97px 8px 0px 10px;
  align-items: center;
  scroll-behavior: smooth;
  width: 100%;
}
.cardsss{
  width: 80%;
}
.arrowContainer {
  width: 50px;
  height: 50px; /* Make sure the arrow container is square */
  border-radius: 50%; /* Change to make the container circular */
  border: 1px solid rgba(255, 255, 255, 0.55);
  background: rgba(255, 153, 0, 0.52);
  cursor: pointer;
  position: absolute;
  top: 60%; /* Center the arrow vertically */
  transform: translateY(-60%); /* Adjust the vertical positioning */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for better visibility */
  z-index: 10; /* Ensure the arrow is on top of other elements */
}

.leftArrow {
  left: 10px; /* Adjust as needed */
}

.rightArrow {
  right: 10px; /* Adjust as needed */
}
.cardlistcontainer{
  max-width: 1272px;
  overflow: hidden;
  margin-top: 30px;
  align-items: stretch; 
  display: flex;
  justify-content: space-between;
}
.cardContent {
  border-radius: 10px;
  position: relative;
  display: flex;
  width: 405px;
  flex-direction: column;
  justify-content: space-between;
  padding: 91px 36px 31px;
  border: 1px solid rgba(143, 44, 90, 1);
  text-align: left;
  flex-shrink: 0; /* Prevent the card from shrinking */
  margin-right: 16px; /* Add spacing between the cards */
  flex-grow: 1;  /* Allow the card to grow and fill the available space */
  height: 100%; 
  box-sizing: border-box;
}

.testimonialText {
  width: 100%;
  font-size: 15px;
  line-height: 23px;
  text-align: left;
  white-space: normal; /* Allow text to wrap to the next line */
  word-wrap: break-word; /* Break long words if needed */
  overflow-wrap: break-word;
}

.authorName {
  margin-top: 16px;
  width: 100%;
  padding-top: 15px;
  font: 700 18px/1 'Cinzel Decorative', -apple-system, Roboto, Helvetica, sans-serif;
  text-align: left;
}

.authorInfo {
  font-size: 16px;
  z-index: 0;
  margin-top: 16px;
}

.box1, .box2, .box3 {
  width: 10px;
  height: 10px;
  position: absolute;
  z-index: 10;
}

.box1 {
  left: 17px;
  top: 34px;
  border: 2px solid var(--rn-53-themes-net-green, #FFC600);
}

.box2 {
  left: 31px;
  top: -67px;
  border: 2px solid var(--rn-53-themes-net-geraldine, #FD8686);
}

.box3 {
  left: 142px;
  top: -55px;
  border: 2px solid var(--rn-53-themes-net-rio-grande, #BDCCFF);
}

.authorImage {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 130px;
  position: absolute;
  z-index: 0;
  max-width: 100%;
  left: 36px;
  top: -64px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .testimonialCard {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }
.cardlistcontainer{
  max-width: 311px;
  justify-content: center;
}
  .cardContent {
    padding: 91px 20px 31px;
    width: 310px;
  }
  .arrowContainer {
    width: 40px;
    height: 40px;
  }
}

